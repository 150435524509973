import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { USAGE_REPORTS_MOCK } from 'app/shared/mocks';
import {
  ProductionReport,
  ProductionReportDTO,
  ReportType,
  UsageReport,
  UsageReportDTO,
} from 'app/shared/models';
import { InfoResponseAPI } from 'app/shared/interfaces';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface ProductionReportResponse {
  productionReports: ProductionReport[];
  info: InfoResponseAPI;
}
export interface ProductionReportsFilterI {
  text?: string;
  type?: ReportType | '';
}

@Injectable({
  providedIn: 'root',
})
export class UsageReportService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getUsageReports(
    offset: number,
    limit: number,
    title: string | null = '',
  ): Promise<{ usageReports: UsageReport[]; info: InfoResponseAPI }> {
    let usageReportsFetched: UsageReport[] = [];
    let filters = `?offset=${offset}&limit=${limit}&title=${title}`;
    let info = {
      count: 0,
      offset: '',
      limit: '',
    };

    if (title !== '' && title !== null) {
      filters += `&name=${title}`;
    }

    if (environment.useMocks) {
      usageReportsFetched = new UsageReport().deserializeArray(USAGE_REPORTS_MOCK.usageReports);

      info = {
        count: usageReportsFetched.length,
        offset: '',
        limit: '',
      };
    } else {
      const url = `${this.apiUrl}/report/me/${filters}`;
      const response = await this.http
        .get<{ productionReports: UsageReportDTO[]; info: InfoResponseAPI }>(url, httpOptions)
        .toPromise();
      const { productionReports } = response;

      info = response.info;
      usageReportsFetched = new UsageReport().deserializeArray(productionReports);
    }
    return { usageReports: usageReportsFetched, info };
  }

  async downloadReportUsageAsCSV(reportUsageId: string): Promise<any> {
    const url = `${this.apiUrl}/reportUsage/${reportUsageId}/csv?cShares=true`;

    return this.http.get(url).toPromise();
  }

  async createProductionReport(productionReport: ProductionReport): Promise<ProductionReport> {
    const url = `${this.apiUrl}/report`;

    const { productionReport: productionReportApi } = await this.http
      .post<{ productionReport: ProductionReportDTO }>(url, productionReport)
      .toPromise();

    return new ProductionReport().deserialize(productionReportApi);
  }

  async updateProductionReport(productionReport: ProductionReport): Promise<ProductionReport> {
    const url = `${this.apiUrl}/report/${productionReport.id}`;

    const { productionReport: productionReportApi } = await this.http
      .put<{ productionReport: ProductionReportDTO }>(url, productionReport)
      .toPromise();

    return new ProductionReport().deserialize(productionReportApi);
  }

  async deleteProductionReport(productionReport: ProductionReport): Promise<void> {
    const url = `${this.apiUrl}/report/${productionReport.id}`;

    await this.http.delete<void>(url).toPromise();
  }

  async getProductionReports(
    offset: number,
    limit: number,
    filters?: ProductionReportsFilterI,
  ): Promise<ProductionReportResponse> {
    let queryString = `?offset=${offset}&limit=${limit}`;

    if (filters && filters.text) {
      queryString += `&text=${filters?.text}`;
    }

    if (filters && filters.type) {
      queryString += `&type=${filters?.type}`;
    }

    const url = `${this.apiUrl}/report/me/${queryString}`;

    const { productionReports: productionReportsApi, info } = await this.http
      .get<{ productionReports: Array<ProductionReportDTO>; info: InfoResponseAPI }>(url)
      .toPromise();

    return {
      productionReports: new ProductionReport().deserializeArray(productionReportsApi),
      info,
    };
  }

  async uploadFile(type: string, file: File): Promise<void> {
    const url = `${this.apiUrl}/report/csv`;
    const formData = new FormData();
    formData.append('csv', file);
    formData.append('type', type);

    await this.http
      .post<{ productionReports: Array<ProductionReportDTO> }>(url, formData)
      .toPromise();
  }
}
