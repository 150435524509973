import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Territory } from '../models';
import { InfoResponseAPI, TerritoriesAPIResponse } from '../interfaces';

export enum TerritoryType {
  COUNTRY = 'country',
  GROUP = 'group',
}
@Injectable({
  providedIn: 'root',
})
export class TerritoryService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getTerritories(
    offset = 0,
    limit = 9999,
    type = '',
  ): Promise<{ territories: Territory[]; info: InfoResponseAPI }> {
    const filters = `?offset=${offset}&limit=${limit}&type=${type}`;
    const url = `${this.apiUrl}/territory/${filters}`;
    const { territories: territoriesApi, info } = await this.http
      .get<TerritoriesAPIResponse>(url)
      .toPromise();

    return {
      territories: new Territory().deserializeArray(territoriesApi),
      info,
    };
  }

  async getTerritoryInclusions(
    tis: number,
    offset: number = 0,
    limit: number = 0,
    type: string = '',
  ): Promise<{ territories: Territory[]; info: InfoResponseAPI }> {
    const params = {
      offset: offset ? offset.toString() : '',
      // limit: limit ? limit.toString() : '',
      type: type ? type.toString() : '',
    };

    const url = `${this.apiUrl}/territory/${tis}/inclusions`;
    const { territories: territoriesApi, info } = await this.http
      .get<TerritoriesAPIResponse>(url, { params })
      .toPromise();

    return {
      territories: new Territory().deserializeArray(territoriesApi),
      info,
    };
  }
}
