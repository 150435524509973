import { LiquidationDTO, UsageTypes, StatementStatus } from 'app/shared/models';

export const LIQUIDATIONS_MOCK_PART: LiquidationDTO[] = [
  {
    userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
    month: 11,
    year: 2020,
    type: UsageTypes.BACKGROUND_MUSIC,
    commission: 20,
    amount: 281.125,
    unisonStatementId: 'bbd16e70-6583-4513-9914-8793951fb600',
    clientStatementId: 'db17550e-db7b-4e15-8615-8e206279d5b0',
    id: '0013297a-f952-4827-bd36-201b6ceffc23',
    createdAt: '2020-12-15T09:34:15.753Z',
    updatedAt: '2020-12-15T09:34:18.173Z',
    // dateFrom: '15/04/2015',
    dateFrom: '2020-11-01T12:57:32.383Z',
    // dateTo: '15/11/2016',
    dateTo: '2020-11-01T12:57:32.383Z',
    user: {
      id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      role: 'right_holder',
      email: 'jgrau@nakima.es',
      firstName: 'JGrau',
      lastName: 'Productions',
      businessName: '',
      nif: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
      commissionBackground: 20,
      commissionDigital: 10,
      commissionLive: 9,
      commissionRadioTV: 10,
      status: '',
      stripeAccountId: '',
      financialAdminId: '',
      rightHolders: [],
    },
    unisonStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'Productions, JGrau',
      senderNIF: '123193212A',
      senderAddress: 'Numancia 73',
      senderCity: 'Barcelona',
      senderCountry: 'Spain',
      senderPostalCode: '08029',
      receiverName: 'UNISON RIGHTS, SL',
      receiverNIF: 'B66840612',
      receiverAddress: 'Passeig de la Zona Franca, 186',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08038',
      subtotalAmount: 56.225,
      totalAmount: 68.03225,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '22ae227b-bf33-4d07-81e5-14aa25350ded',
      id: 'bbd16e70-6583-4513-9914-8793951fb600',
      statementNumber: 10000954,
      createdAt: '2020-12-15T09:34:17.833Z',
      updatedAt: '2020-12-15T09:34:18.179Z',
    },
    clientStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'UNISON RIGHTS, SL',
      senderNIF: 'B66840612',
      senderAddress: '08038',
      senderCity: 'Passeig de la Zona Franca, 186',
      senderCountry: 'Barcelona',
      senderPostalCode: 'Spain',
      receiverName: 'Productions, JGrau',
      receiverNIF: '123193212A',
      receiverAddress: 'Numancia 73',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08029',
      subtotalAmount: 224.9,
      totalAmount: 272.129,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '4b5915b1-cefe-4330-8150-8dddd2d9f585',
      id: 'db17550e-db7b-4e15-8615-8e206279d5b0',
      statementNumber: 10000953,
      createdAt: '2020-12-15T09:34:17.809Z',
      updatedAt: '2020-12-15T09:34:18.179Z',
    },
  },
  {
    userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
    month: 11,
    year: 2020,
    type: UsageTypes.RADIO_TV,
    commission: 10,
    amount: 244,
    unisonStatementId: '0b68a1d4-cf36-4a64-ae9b-0b759a64dbcc',
    clientStatementId: '19dce3f6-78ec-4b0e-9848-fb73e52a50fc',
    id: '42104066-c55b-4054-98a5-8e968add4e28',
    createdAt: '2020-12-15T09:34:15.872Z',
    updatedAt: '2020-12-15T09:34:19.561Z',
    // dateFrom: '15/11/2016',
    dateFrom: '2020-11-01T12:57:32.383Z',
    // dateTo: '15/04/2017',
    dateTo: '2020-11-01T12:57:32.383Z',
    user: {
      id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      role: 'right_holder',
      email: 'jgrau@nakima.es',
      firstName: 'JGrau',
      lastName: 'Productions',
      businessName: '',
      nif: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
      commissionBackground: 20,
      commissionDigital: 10,
      commissionLive: 9,
      commissionRadioTV: 10,
      status: '',
      stripeAccountId: '',
      financialAdminId: '',
      rightHolders: [],
    },
    unisonStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'Productions, JGrau',
      senderNIF: '123193212A',
      senderAddress: 'Numancia 73',
      senderCity: 'Barcelona',
      senderCountry: 'Spain',
      senderPostalCode: '08029',
      receiverName: 'UNISON RIGHTS, SL',
      receiverNIF: 'B66840612',
      receiverAddress: 'Passeig de la Zona Franca, 186',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08038',
      subtotalAmount: 24.4,
      totalAmount: 29.524,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '61adaf9a-14d7-4eb8-b0c5-b4f04688f782',
      id: '0b68a1d4-cf36-4a64-ae9b-0b759a64dbcc',
      statementNumber: 10000960,
      createdAt: '2020-12-15T09:34:19.172Z',
      updatedAt: '2020-12-15T09:34:19.562Z',
    },
    clientStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'UNISON RIGHTS, SL',
      senderNIF: 'B66840612',
      senderAddress: '08038',
      senderCity: 'Passeig de la Zona Franca, 186',
      senderCountry: 'Barcelona',
      senderPostalCode: 'Spain',
      receiverName: 'Productions, JGrau',
      receiverNIF: '123193212A',
      receiverAddress: 'Numancia 73',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08029',
      subtotalAmount: 219.6,
      totalAmount: 265.716,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '66f62ce3-3113-4556-8b2f-1428a5301d09',
      id: '19dce3f6-78ec-4b0e-9848-fb73e52a50fc',
      statementNumber: 10000959,
      createdAt: '2020-12-15T09:34:19.144Z',
      updatedAt: '2020-12-15T09:34:19.562Z',
    },
  },
  {
    userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
    month: 11,
    year: 2020,
    type: UsageTypes.DIGITAL,
    commission: 10,
    amount: 195,
    unisonStatementId: '14e031ea-166e-43fc-8105-09fae498df79',
    clientStatementId: 'fd28b65d-b3f4-43a7-9716-b57d134dd8e1',
    id: '9d9c987f-b1b2-42ec-bb9f-87fe159b3164',
    createdAt: '2020-12-15T09:34:16.298Z',
    updatedAt: '2020-12-15T09:34:22.235Z',
    // dateFrom: '15/04/2017',
    dateFrom: '2020-11-01T12:57:32.383Z',
    // dateTo: '15/11/2018',
    dateTo: '2020-11-01T12:57:32.383Z',
    user: {
      id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      role: 'right_holder',
      email: 'jgrau@nakima.es',
      firstName: 'JGrau',
      lastName: 'Productions',
      businessName: '',
      nif: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
      commissionBackground: 20,
      commissionDigital: 10,
      commissionLive: 9,
      commissionRadioTV: 10,
      status: '',
      stripeAccountId: '',
      financialAdminId: '',
      rightHolders: [],
    },
    unisonStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'Productions, JGrau',
      senderNIF: '123193212A',
      senderAddress: 'Numancia 73',
      senderCity: 'Barcelona',
      senderCountry: 'Spain',
      senderPostalCode: '08029',
      receiverName: 'UNISON RIGHTS, SL',
      receiverNIF: 'B66840612',
      receiverAddress: 'Passeig de la Zona Franca, 186',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08038',
      subtotalAmount: 19.5,
      totalAmount: 23.595,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '5fdd57a3-7fd7-49be-9f30-bf8b84578781',
      id: '14e031ea-166e-43fc-8105-09fae498df79',
      statementNumber: 10000972,
      createdAt: '2020-12-15T09:34:21.862Z',
      updatedAt: '2020-12-15T09:34:22.237Z',
    },
    clientStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'UNISON RIGHTS, SL',
      senderNIF: 'B66840612',
      senderAddress: '08038',
      senderCity: 'Passeig de la Zona Franca, 186',
      senderCountry: 'Barcelona',
      senderPostalCode: 'Spain',
      receiverName: 'Productions, JGrau',
      receiverNIF: '123193212A',
      receiverAddress: 'Numancia 73',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08029',
      subtotalAmount: 175.5,
      totalAmount: 212.355,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '98ac490d-d69f-4b93-ab78-6ce116b9e45c',
      id: 'fd28b65d-b3f4-43a7-9716-b57d134dd8e1',
      statementNumber: 10000971,
      createdAt: '2020-12-15T09:34:21.837Z',
      updatedAt: '2020-12-15T09:34:22.236Z',
    },
  },
  {
    userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
    month: 11,
    year: 2020,
    type: UsageTypes.LIVE,
    commission: 9,
    amount: 164.75,
    unisonStatementId: 'ba3b3c80-a924-4c40-90e4-29dfe91e5a0b',
    clientStatementId: 'bf4b8cf3-3de1-4d55-b82a-e7cc73e2cca0',
    id: 'dc12be60-aa80-4dfa-840a-8086d94ce3ce',
    createdAt: '2020-12-15T09:34:16.265Z',
    updatedAt: '2020-12-15T09:34:21.289Z',
    // dateFrom: '15/11/2018',
    dateFrom: '2020-11-01T12:57:32.383Z',
    // dateTo: '15/04/2019',
    dateTo: '2020-11-01T12:57:32.383Z',
    user: {
      id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      role: 'right_holder',
      email: 'jgrau@nakima.es',
      firstName: 'JGrau',
      lastName: 'Productions',
      businessName: '',
      nif: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
      commissionBackground: 20,
      commissionDigital: 10,
      commissionLive: 9,
      commissionRadioTV: 10,
      status: '',
      stripeAccountId: '',
      financialAdminId: '',
      rightHolders: [],
    },
    unisonStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'Productions, JGrau',
      senderNIF: '123193212A',
      senderAddress: 'Numancia 73',
      senderCity: 'Barcelona',
      senderCountry: 'Spain',
      senderPostalCode: '08029',
      receiverName: 'UNISON RIGHTS, SL',
      receiverNIF: 'B66840612',
      receiverAddress: 'Passeig de la Zona Franca, 186',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08038',
      subtotalAmount: 14.8275,
      totalAmount: 17.941275,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '8786e0ad-f06d-4d6e-bd4d-45e356e2f629',
      id: 'ba3b3c80-a924-4c40-90e4-29dfe91e5a0b',
      statementNumber: 10000968,
      createdAt: '2020-12-15T09:34:20.906Z',
      updatedAt: '2020-12-15T09:34:21.290Z',
    },
    clientStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'UNISON RIGHTS, SL',
      senderNIF: 'B66840612',
      senderAddress: '08038',
      senderCity: 'Passeig de la Zona Franca, 186',
      senderCountry: 'Barcelona',
      senderPostalCode: 'Spain',
      receiverName: 'Productions, JGrau',
      receiverNIF: '123193212A',
      receiverAddress: 'Numancia 73',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08029',
      subtotalAmount: 149.9225,
      totalAmount: 181.406225,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '52b5c4ca-eb5d-4308-947f-92d7ee8aab9e',
      id: 'bf4b8cf3-3de1-4d55-b82a-e7cc73e2cca0',
      statementNumber: 10000967,
      createdAt: '2020-12-15T09:34:20.883Z',
      updatedAt: '2020-12-15T09:34:21.289Z',
    },
  },
  {
    userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
    month: 9,
    year: 2020,
    type: UsageTypes.BACKGROUND_MUSIC,
    commission: 20,
    amount: 191.875,
    unisonStatementId: '85eb2cd2-3659-4fd6-adb2-2bf6eae528d4',
    clientStatementId: '410d1d14-35bf-4ac8-94b0-3e5cb67a822e',
    id: 'a015e48c-83b5-4d33-8f26-4c420638595c',
    createdAt: '2020-12-15T09:34:06.525Z',
    updatedAt: '2020-12-15T09:34:09.699Z',
    // dateFrom: '15/04/2019',
    dateFrom: '2020-11-01T12:57:32.383Z',
    // dateTo: '15/11/2019',
    dateTo: '2020-11-01T12:57:32.383Z',
    user: {
      id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      role: 'right_holder',
      email: 'jgrau@nakima.es',
      firstName: 'JGrau',
      lastName: 'Productions',
      businessName: '',
      nif: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
      commissionBackground: 20,
      commissionDigital: 10,
      commissionLive: 9,
      commissionRadioTV: 10,
      status: '',
      stripeAccountId: '',
      financialAdminId: '',
      rightHolders: [],
    },
    unisonStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'Productions, JGrau',
      senderNIF: '123193212A',
      senderAddress: 'Numancia 73',
      senderCity: 'Barcelona',
      senderCountry: 'Spain',
      senderPostalCode: '08029',
      receiverName: 'UNISON RIGHTS, SL',
      receiverNIF: 'B66840612',
      receiverAddress: 'Passeig de la Zona Franca, 186',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08038',
      subtotalAmount: 38.375,
      totalAmount: 46.43375,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '6e9586fb-9c45-42fb-beb4-a8d316112aca',
      id: '85eb2cd2-3659-4fd6-adb2-2bf6eae528d4',
      statementNumber: 10000926,
      createdAt: '2020-12-15T09:34:09.360Z',
      updatedAt: '2020-12-15T09:34:09.700Z',
    },
    clientStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'UNISON RIGHTS, SL',
      senderNIF: 'B66840612',
      senderAddress: '08038',
      senderCity: 'Passeig de la Zona Franca, 186',
      senderCountry: 'Barcelona',
      senderPostalCode: 'Spain',
      receiverName: 'Productions, JGrau',
      receiverNIF: '123193212A',
      receiverAddress: 'Numancia 73',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08029',
      subtotalAmount: 153.5,
      totalAmount: 185.735,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: 'a11a5778-154e-4ec4-a582-0cfb25aec889',
      id: '410d1d14-35bf-4ac8-94b0-3e5cb67a822e',
      statementNumber: 10000925,
      createdAt: '2020-12-15T09:34:09.339Z',
      updatedAt: '2020-12-15T09:34:09.700Z',
    },
  },
  {
    userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
    month: 9,
    year: 2020,
    type: UsageTypes.DIGITAL,
    commission: 10,
    amount: 134.75,
    unisonStatementId: '3ef416d2-1c8f-4665-9ebc-649b7650a2fb',
    clientStatementId: '51e43c82-8fc4-47d3-8413-32bfec71e4de',
    id: 'dee07664-5a1e-4b92-b696-3a8480d4cbed',
    createdAt: '2020-12-15T09:34:07.037Z',
    updatedAt: '2020-12-15T09:34:13.304Z',
    // dateFrom: '15/11/2019',
    dateFrom: '2020-11-01T12:57:32.383Z',
    // dateTo: '15/04/2020',
    dateTo: '2020-11-01T12:57:32.383Z',
    user: {
      id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
      role: 'right_holder',
      email: 'jgrau@nakima.es',
      firstName: 'JGrau',
      lastName: 'Productions',
      businessName: '',
      nif: '',
      address: '',
      city: '',
      postalCode: '',
      country: '',
      commissionBackground: 20,
      commissionDigital: 10,
      commissionLive: 9,
      commissionRadioTV: 10,
      status: '',
      stripeAccountId: '',
      financialAdminId: '',
      rightHolders: [],
    },
    unisonStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'Productions, JGrau',
      senderNIF: '123193212A',
      senderAddress: 'Numancia 73',
      senderCity: 'Barcelona',
      senderCountry: 'Spain',
      senderPostalCode: '08029',
      receiverName: 'UNISON RIGHTS, SL',
      receiverNIF: 'B66840612',
      receiverAddress: 'Passeig de la Zona Franca, 186',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08038',
      subtotalAmount: 13.475,
      totalAmount: 16.30475,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '93b3242e-40b5-42bf-a403-cb5b45102a9a',
      id: '3ef416d2-1c8f-4665-9ebc-649b7650a2fb',
      statementNumber: 10000938,
      createdAt: '2020-12-15T09:34:12.900Z',
      updatedAt: '2020-12-15T09:34:13.306Z',
    },
    clientStatement: {
      description: 'Rights management until 12/15/2020',
      senderName: 'UNISON RIGHTS, SL',
      senderNIF: 'B66840612',
      senderAddress: '08038',
      senderCity: 'Passeig de la Zona Franca, 186',
      senderCountry: 'Barcelona',
      senderPostalCode: 'Spain',
      receiverName: 'Productions, JGrau',
      receiverNIF: '123193212A',
      receiverAddress: 'Numancia 73',
      receiverCity: 'Barcelona',
      receiverCountry: 'Spain',
      receiverPostalCode: '08029',
      subtotalAmount: 121.275,
      totalAmount: 146.74275,
      status: StatementStatus.PENDING,
      currencyId: 'EUR',
      resourceId: '660727c4-cf69-412d-9a36-6347da67eba7',
      id: '51e43c82-8fc4-47d3-8413-32bfec71e4de',
      statementNumber: 10000937,
      createdAt: '2020-12-15T09:34:12.871Z',
      updatedAt: '2020-12-15T09:34:13.305Z',
    },
  },
  // {
  //   userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
  //   month: 9,
  //   year: 2020,
  //   type: UsageTypes.RADIO_TV,
  //   commission: 10,
  //   amount: 100.875,
  //   unisonStatementId: '91c8ad0f-02a0-4cd6-8782-699e54284e9e',
  //   clientStatementId: '8e121b8d-2d54-40aa-bd59-890f2db99940',
  //   id: '29271d3a-054e-4943-ae5b-94264970741d',
  //   createdAt: '2020-12-15T09:34:06.459Z',
  //   updatedAt: '2020-12-15T09:34:08.858Z',
  //   dateFrom: '',
  // dateFrom: '2020-11-01T12:57:32.383Z',
  //   dateTo: '',
  // dateTo: '2020-11-01T12:57:32.383Z'
  //   user: {
  //     id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
  //     role: 'right_holder',
  //     email: 'jgrau@nakima.es',
  //     firstName: 'JGrau',
  //     lastName: 'Productions',
  //     businessName: '',
  //     nif: '',
  //     address: '',
  //     city: '',
  //     postalCode: '',
  //     country: '',
  //     commissionBackground: 20,
  //     commissionDigital: 10,
  //     commissionLive: 9,
  //     commissionRadioTV: 10,
  //     status: '',
  //     stripeAccountId: '',
  //     financialAdminId: '',
  //     rightHolders: [],
  //   },
  //   unisonStatement: {
  //     description: 'Rights management until 12/15/2020',
  //     senderName: 'Productions, JGrau',
  //     senderNIF: '123193212A',
  //     senderAddress: 'Numancia 73',
  //     senderCity: 'Barcelona',
  //     senderCountry: 'Spain',
  //     senderPostalCode: '08029',
  //     receiverName: 'UNISON RIGHTS, SL',
  //     receiverNIF: 'B66840612',
  //     receiverAddress: 'Passeig de la Zona Franca, 186',
  //     receiverCity: 'Barcelona',
  //     receiverCountry: 'Spain',
  //     receiverPostalCode: '08038',
  //     subtotalAmount: 10.0875,
  //     totalAmount: 12.205875,
  //     status: StatementStatus.PENDING,
  //     currencyId: 'EUR',
  //     resourceId: '0dc5f7c1-0ca1-4ba4-8527-f42fee9a5f6f',
  //     id: '91c8ad0f-02a0-4cd6-8782-699e54284e9e',
  //     statementNumber: 10000922,
  //     createdAt: '2020-12-15T09:34:08.499Z',
  //     updatedAt: '2020-12-15T09:34:08.859Z',
  //   },
  //   clientStatement: {
  //     description: 'Rights management until 12/15/2020',
  //     senderName: 'UNISON RIGHTS, SL',
  //     senderNIF: 'B66840612',
  //     senderAddress: '08038',
  //     senderCity: 'Passeig de la Zona Franca, 186',
  //     senderCountry: 'Barcelona',
  //     senderPostalCode: 'Spain',
  //     receiverName: 'Productions, JGrau',
  //     receiverNIF: '123193212A',
  //     receiverAddress: 'Numancia 73',
  //     receiverCity: 'Barcelona',
  //     receiverCountry: 'Spain',
  //     receiverPostalCode: '08029',
  //     subtotalAmount: 90.7875,
  //     totalAmount: 109.852875,
  //     status: StatementStatus.PENDING,
  //     currencyId: 'EUR',
  //     resourceId: '381e3528-cb41-4263-b0de-91b6b4ac129e',
  //     id: '8e121b8d-2d54-40aa-bd59-890f2db99940',
  //     statementNumber: 10000921,
  //     createdAt: '2020-12-15T09:34:08.477Z',
  //     updatedAt: '2020-12-15T09:34:08.859Z',
  //   },
  // },
  // {
  //   userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
  //   month: 9,
  //   year: 2020,
  //   type: UsageTypes.LIVE,
  //   commission: 9,
  //   amount: 92.875,
  //   unisonStatementId: 'bbbb1127-f8d2-4924-825f-73b249b6949a',
  //   clientStatementId: '88a8caf2-276d-4982-846d-b28bd5d96ede',
  //   id: 'bb6aa842-9f41-4388-8c9f-7611dc658398',
  //   createdAt: '2020-12-15T09:34:07.142Z',
  //   updatedAt: '2020-12-15T09:34:14.982Z',
  //   dateFrom: '',
  // dateFrom: '2020-11-01T12:57:32.383Z',
  //   dateTo: '',
  // dateTo: '2020-11-01T12:57:32.383Z'
  //   user: {
  //     id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
  //     role: 'right_holder',
  //     email: 'jgrau@nakima.es',
  //     firstName: 'JGrau',
  //     lastName: 'Productions',
  //     businessName: '',
  //     nif: '',
  //     address: '',
  //     city: '',
  //     postalCode: '',
  //     country: '',
  //     commissionBackground: 20,
  //     commissionDigital: 10,
  //     commissionLive: 9,
  //     commissionRadioTV: 10,
  //     status: '',
  //     stripeAccountId: '',
  //     financialAdminId: '',
  //     rightHolders: [],
  //   },
  //   unisonStatement: {
  //     description: 'Rights management until 12/15/2020',
  //     senderName: 'Productions, JGrau',
  //     senderNIF: '123193212A',
  //     senderAddress: 'Numancia 73',
  //     senderCity: 'Barcelona',
  //     senderCountry: 'Spain',
  //     senderPostalCode: '08029',
  //     receiverName: 'UNISON RIGHTS, SL',
  //     receiverNIF: 'B66840612',
  //     receiverAddress: 'Passeig de la Zona Franca, 186',
  //     receiverCity: 'Barcelona',
  //     receiverCountry: 'Spain',
  //     receiverPostalCode: '08038',
  //     subtotalAmount: 8.35875,
  //     totalAmount: 10.1140875,
  //     status: StatementStatus.PENDING,
  //     currencyId: 'EUR',
  //     resourceId: 'ec526ff9-278a-4d3b-9974-24e42c7643b7',
  //     id: 'bbbb1127-f8d2-4924-825f-73b249b6949a',
  //     statementNumber: 10000946,
  //     createdAt: '2020-12-15T09:34:14.619Z',
  //     updatedAt: '2020-12-15T09:34:14.984Z',
  //   },
  //   clientStatement: {
  //     description: 'Rights management until 12/15/2020',
  //     senderName: 'UNISON RIGHTS, SL',
  //     senderNIF: 'B66840612',
  //     senderAddress: '08038',
  //     senderCity: 'Passeig de la Zona Franca, 186',
  //     senderCountry: 'Barcelona',
  //     senderPostalCode: 'Spain',
  //     receiverName: 'Productions, JGrau',
  //     receiverNIF: '123193212A',
  //     receiverAddress: 'Numancia 73',
  //     receiverCity: 'Barcelona',
  //     receiverCountry: 'Spain',
  //     receiverPostalCode: '08029',
  //     subtotalAmount: 84.51625,
  //     totalAmount: 102.2646625,
  //     status: StatementStatus.PENDING,
  //     currencyId: 'EUR',
  //     resourceId: '5f96d243-d6b6-428a-95f8-e7f47e309569',
  //     id: '88a8caf2-276d-4982-846d-b28bd5d96ede',
  //     statementNumber: 10000945,
  //     createdAt: '2020-12-15T09:34:14.599Z',
  //     updatedAt: '2020-12-15T09:34:14.983Z',
  //   },
  // },
  // {
  //   userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
  //   month: 8,
  //   year: 2020,
  //   type: UsageTypes.RADIO_TV,
  //   commission: 10,
  //   amount: 155.625,
  //   unisonStatementId: '19d1a5ca-699d-4a05-80e8-51694fcacbb4',
  //   clientStatementId: '3361e55a-de80-40b8-8763-0827401d3d00',
  //   id: '7f4320ee-bd8c-4e3e-94e7-ecdcc1d41ee5',
  //   createdAt: '2020-12-15T09:33:58.783Z',
  //   updatedAt: '2020-12-15T09:34:01.130Z',
  //   dateFrom: '',
  // dateFrom: '2020-11-01T12:57:32.383Z',
  //   dateTo: '',
  // dateTo: '2020-11-01T12:57:32.383Z'
  //   user: {
  //     id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
  //     role: 'right_holder',
  //     email: 'jgrau@nakima.es',
  //     firstName: 'JGrau',
  //     lastName: 'Productions',
  //     businessName: '',
  //     nif: '',
  //     address: '',
  //     city: '',
  //     postalCode: '',
  //     country: '',
  //     commissionBackground: 20,
  //     commissionDigital: 10,
  //     commissionLive: 9,
  //     commissionRadioTV: 10,
  //     status: '',
  //     stripeAccountId: '',
  //     financialAdminId: '',
  //     rightHolders: [],
  //   },
  //   unisonStatement: {
  //     description: 'Rights management until 12/15/2020',
  //     senderName: 'Productions, JGrau',
  //     senderNIF: '123193212A',
  //     senderAddress: 'Numancia 73',
  //     senderCity: 'Barcelona',
  //     senderCountry: 'Spain',
  //     senderPostalCode: '08029',
  //     receiverName: 'UNISON RIGHTS, SL',
  //     receiverNIF: 'B66840612',
  //     receiverAddress: 'Passeig de la Zona Franca, 186',
  //     receiverCity: 'Barcelona',
  //     receiverCountry: 'Spain',
  //     receiverPostalCode: '08038',
  //     subtotalAmount: 15.5625,
  //     totalAmount: 18.830625,
  //     status: StatementStatus.PENDING,
  //     currencyId: 'EUR',
  //     resourceId: '4603a4ca-c258-4a61-bac5-9f9e13c8a455',
  //     id: '19d1a5ca-699d-4a05-80e8-51694fcacbb4',
  //     statementNumber: 10000890,
  //     createdAt: '2020-12-15T09:34:00.778Z',
  //     updatedAt: '2020-12-15T09:34:01.131Z',
  //   },
  //   clientStatement: {
  //     description: 'Rights management until 12/15/2020',
  //     senderName: 'UNISON RIGHTS, SL',
  //     senderNIF: 'B66840612',
  //     senderAddress: '08038',
  //     senderCity: 'Passeig de la Zona Franca, 186',
  //     senderCountry: 'Barcelona',
  //     senderPostalCode: 'Spain',
  //     receiverName: 'Productions, JGrau',
  //     receiverNIF: '123193212A',
  //     receiverAddress: 'Numancia 73',
  //     receiverCity: 'Barcelona',
  //     receiverCountry: 'Spain',
  //     receiverPostalCode: '08029',
  //     subtotalAmount: 140.0625,
  //     totalAmount: 169.475625,
  //     status: StatementStatus.PENDING,
  //     currencyId: 'EUR',
  //     resourceId: 'bf885a07-d61c-4c87-9f7b-48cc13179151',
  //     id: '3361e55a-de80-40b8-8763-0827401d3d00',
  //     statementNumber: 10000889,
  //     createdAt: '2020-12-15T09:34:00.752Z',
  //     updatedAt: '2020-12-15T09:34:01.131Z',
  //   },
  // },
  // {
  //   userId: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
  //   month: 8,
  //   year: 2020,
  //   type: UsageTypes.BACKGROUND_MUSIC,
  //   commission: 20,
  //   amount: 201,
  //   unisonStatementId: '04f3280e-5f94-4238-99ba-f7d018c491bc',
  //   clientStatementId: '57f964a1-bc04-4085-8bce-40c43208984f',
  //   id: 'aec52514-1197-44b7-bb75-dbd9b1e5baf5',
  //   createdAt: '2020-12-15T09:33:58.756Z',
  //   updatedAt: '2020-12-15T09:34:00.703Z',
  //   dateFrom: '',
  // dateFrom: '2020-11-01T12:57:32.383Z',
  //   dateTo: '',
  // dateTo: '2020-11-01T12:57:32.383Z'
  //   user: {
  //     id: '1d6494f8-a6ab-11e8-98d0-529269fb1459',
  //     role: 'right_holder',
  //     email: 'jgrau@nakima.es',
  //     firstName: 'JGrau',
  //     lastName: 'Productions',
  //     businessName: '',
  //     nif: '',
  //     address: '',
  //     city: '',
  //     postalCode: '',
  //     country: '',
  //     commissionBackground: 20,
  //     commissionDigital: 10,
  //     commissionLive: 9,
  //     commissionRadioTV: 10,
  //     status: '',
  //     stripeAccountId: '',
  //     financialAdminId: '',
  //     rightHolders: [],
  //   },
  //   unisonStatement: {
  //     description: 'Rights management until 12/15/2020',
  //     senderName: 'Productions, JGrau',
  //     senderNIF: '123193212A',
  //     senderAddress: 'Numancia 73',
  //     senderCity: 'Barcelona',
  //     senderCountry: 'Spain',
  //     senderPostalCode: '08029',
  //     receiverName: 'UNISON RIGHTS, SL',
  //     receiverNIF: 'B66840612',
  //     receiverAddress: 'Passeig de la Zona Franca, 186',
  //     receiverCity: 'Barcelona',
  //     receiverCountry: 'Spain',
  //     receiverPostalCode: '08038',
  //     subtotalAmount: 40.2,
  //     totalAmount: 48.642,
  //     status: StatementStatus.PENDING,
  //     currencyId: 'EUR',
  //     resourceId: '8d1ac29e-9115-472f-bf8b-8e18f6da4397',
  //     id: '04f3280e-5f94-4238-99ba-f7d018c491bc',
  //     statementNumber: 10000888,
  //     createdAt: '2020-12-15T09:34:00.371Z',
  //     updatedAt: '2020-12-15T09:34:00.706Z',
  //   },
  //   clientStatement: {
  //     description: 'Rights management until 12/15/2020',
  //     senderName: 'UNISON RIGHTS, SL',
  //     senderNIF: 'B66840612',
  //     senderAddress: '08038',
  //     senderCity: 'Passeig de la Zona Franca, 186',
  //     senderCountry: 'Barcelona',
  //     senderPostalCode: 'Spain',
  //     receiverName: 'Productions, JGrau',
  //     receiverNIF: '123193212A',
  //     receiverAddress: 'Numancia 73',
  //     receiverCity: 'Barcelona',
  //     receiverCountry: 'Spain',
  //     receiverPostalCode: '08029',
  //     subtotalAmount: 160.8,
  //     totalAmount: 194.568,
  //     status: StatementStatus.PENDING,
  //     currencyId: 'EUR',
  //     resourceId: 'ed286254-8f68-448b-91b8-43b5f9966aa7',
  //     id: '57f964a1-bc04-4085-8bce-40c43208984f',
  //     statementNumber: 10000887,
  //     createdAt: '2020-12-15T09:34:00.348Z',
  //     updatedAt: '2020-12-15T09:34:00.705Z',
  //   },
  // },
];

export const LIQUIDATIONS_MOCK: LiquidationDTO[] = [
  ...LIQUIDATIONS_MOCK_PART,
  ...LIQUIDATIONS_MOCK_PART,
  ...LIQUIDATIONS_MOCK_PART,
  ...LIQUIDATIONS_MOCK_PART,
  ...LIQUIDATIONS_MOCK_PART,
  ...LIQUIDATIONS_MOCK_PART,
  ...LIQUIDATIONS_MOCK_PART,
];
