import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';

import {
  Currency,
  FinancialData,
  RightHolder,
  RightHolderDTO,
  Territory,
  User,
  UserDTO,
} from 'app/shared/models';
import { RIGHTHOLDERS_MOCK, RIGHTHOLDER_MOCK } from 'app/shared/mocks';
import { AuthService } from 'app/modules/auth/services';
import {
  CurrenciesAPIResponse,
  FinancialDataAPIResponse,
  TerritoriesAPIResponse,
} from 'app/shared/interfaces';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

interface NewPasswordI {
  newPassword: string;
  password: string;
}

interface RightHoldersResponse {
  users: RightHolderDTO[];
  info: {
    count: number;
    offset: number;
    limit: number;
  };
}
interface RightHolderAPIResponse {
  rightHolder: RightHolderDTO;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) {}

  async getUsers(offset: number, limit: number, text: string): Promise<{ users: User[] }> {
    let filters = `?offset=${offset}&limit=${limit}`;

    if (text) {
      filters += `&text=${text}`;
    }

    const url = `${this.apiUrl}/user/${filters}`;

    const usersFiltered = await this.http
      .get<{ users: Array<User> }>(url)
      .pipe(
        map((res) => {
          const { users } = res;

          return users.filter((user) => user.role !== 'admin');
        }),
      )
      .toPromise();

    return { users: usersFiltered };
  }

  async getRightHolders(offset: number, limit: number, text: string): Promise<RightHolder[]> {
    let rightHoldersFetched: RightHolder[] = [];
    const filters = `?offset=${offset}&limit=${limit}&text=${text}`;

    if (environment.useMocks) {
      rightHoldersFetched = new RightHolder().deserializeArray(RIGHTHOLDERS_MOCK);
    } else {
      const url = `${this.apiUrl}/user/${filters}`;
      const response: RightHoldersResponse = await this.http
        .get<RightHoldersResponse>(url, httpOptions)
        .toPromise();
      const { users } = response;
      rightHoldersFetched = new RightHolder().deserializeArray(users);
    }
    return rightHoldersFetched;
  }

  async createRightHolder(rightHolder: RightHolder): Promise<RightHolder> {
    let rightHolderFetched: RightHolder = null;

    if (environment.useMocks) {
      rightHolderFetched = new RightHolder().deserialize(RIGHTHOLDER_MOCK);
    } else {
      const url = `${this.apiUrl}/user/right-holder`;
      const response: RightHolderAPIResponse = await this.http
        .post<RightHolderAPIResponse>(url, rightHolder, httpOptions)
        .toPromise();
      rightHolderFetched = new RightHolder().deserialize(response.rightHolder);
    }
    return rightHolderFetched;
  }

  async updateProfile(user: User): Promise<User> {
    let userUpdated: User;
    if (environment.useMocks) {
      // const session = this.authService.getCurrentSession();
      // session.user.email = user.email;
      // session.user.firstName = user.firstName;
      // session.user.email = user.email;
    } else {
      const url = `${this.apiUrl}/user/me/`;
      const response: UserDTO = await this.http.put<UserDTO>(url, user, httpOptions).toPromise();
      userUpdated = new User().deserialize(response);
    }
    return userUpdated;
  }

  async changeCurrentUserPassword(data: NewPasswordI): Promise<boolean> {
    let passwordChanged = false;
    if (environment.useMocks) {
      passwordChanged = true;
    } else {
      const url = `${this.apiUrl}/user/me/change-password`;
      try {
        await this.http.put(url, data, httpOptions).toPromise();
        passwordChanged = true;
      } catch (error) {
        // console.log(error);
      }
    }
    return passwordChanged;
  }

  async getFinancialData(): Promise<FinancialData> {
    let financialDataFetched: FinancialData;
    if (environment.useMocks) {
      // financialDataFetched = new FinancialData().deserialize(FINANCIALDATA_BUSINESS);
      // financialDataFetched = new FinancialData().deserialize(FINANCIALDATA_INDIVIDUAL);
      financialDataFetched = new FinancialData();
    } else {
      const url = `${this.apiUrl}/user/me/financial-data`;
      const response: FinancialDataAPIResponse = await this.http
        .get<FinancialDataAPIResponse>(url)
        .toPromise();
      if (response.financialData.type) {
        financialDataFetched = new FinancialData().deserialize(response.financialData);
      } else {
        financialDataFetched = new FinancialData();
      }
    }
    return financialDataFetched;
  }

  async updateFinancialData(financialData: FinancialData): Promise<void> {
    if (environment.useMocks) {
      // console.log("UPDATED FINANCIAL DATA");
    } else {
      const data = {
        financialData,
      };
      const url = `${this.apiUrl}/user/me/financial-data`;
      await this.http.put(url, data).toPromise();
    }
  }

  async getCountries(offset = 0, limit = 999999): Promise<Array<Territory>> {
    let countriesFetched: Array<Territory> = [];
    const country = 'country';
    const filterCountry = `?type=${country}`;
    const filters = `&offset=${offset}&limit=${limit}`;

    const url = `${this.apiUrl}/territory${filterCountry}${filters}`;
    try {
      const response: TerritoriesAPIResponse = await this.http
        .get<TerritoriesAPIResponse>(url)
        .toPromise();
      countriesFetched = new Territory().deserializeArray(response.territories);
    } catch (error) {
      // console.log("UserService -> error", error)
    }

    return countriesFetched;
  }

  async getCurrencies(offset = 0, limit = 9999): Promise<Array<Currency>> {
    let currenciesFetched: Array<Currency> = [];
    const filters = `?offset=${offset}&limit=${limit}`;
    const url = `${this.apiUrl}/currency/${filters}`;

    try {
      const response: CurrenciesAPIResponse = await this.http
        .get<CurrenciesAPIResponse>(url)
        .toPromise();
      currenciesFetched = new Currency().deserializeArray(response.currencies);
    } catch (error) {
      // console.log("UserService -> error", error)
    }

    return currenciesFetched;
  }
}
