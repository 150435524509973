import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from 'app/modules/auth/guards';
import { P404PageComponent } from 'app/shared/pages';
import { MaintenanceGuard } from './modules/maintenance/guards';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'open-catalog',
    canActivate: [MaintenanceGuard],
    loadChildren: () =>
      import('./modules/open-catalog/open-catalog.module').then((m) => m.OpenCatalogModule),
  },
  {
    path: '',
    canActivate: [LoggedInGuard, MaintenanceGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'repertoire',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./modules/partners/partners.module').then((m) => m.PartnersModule),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./modules/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  {
    path: '**',
    canActivate: [MaintenanceGuard],
    component: P404PageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
