/* eslint-disable import/no-cycle */

import { Serializable } from 'app/core/interfaces';

import {
  CollectionShareLiquidation,
  CollectionShareLiquidationDTO,
} from './collectionShareLiquidation';
import { StatementDTO, Statement } from './statement';

import { UsageTypes } from './usage';
import { User, UserDTO } from './user';
// eslint-disable-next-line import/no-cycle
// import {
//   CollectionShareLiquidation,
//   CollectionShareLiquidationAPI,
// } from './collectionShareLiquidation';
// import { User, UserAPI } from './user';

export interface LiquidationDTO {
  id?: string;
  month?: number;
  year?: number;
  amount?: number;
  type: UsageTypes;
  user?: UserDTO;
  userId?: string;
  collectionShareLiquidations?: CollectionShareLiquidationDTO[];
  updatedAt?: string;
  createdAt?: string;
  unisonStatementId?: string;
  unisonStatement: StatementDTO;
  clientStatementId?: string;
  clientStatement: StatementDTO;
  commission: number;
  dateFrom?: string;
  dateTo?: string;

  // commissionAmount?: number;
  // subtotalAmount?: number;
  // taxAmount?: number;
  // totalAmount?: number;
  // liquidationNumber?: number;
  // status?: string;
}

export class Liquidation implements Serializable<Liquidation> {
  id?: string;
  month?: number;
  year?: number;
  amount?: number;
  type: UsageTypes;
  user?: User;
  userId?: string;
  collectionShareLiquidations?: CollectionShareLiquidation[];
  updatedAt?: string;
  createdAt?: string;
  unisonStatementId?: string;
  unisonStatement: Statement;
  clientStatementId?: string;
  clientStatement: Statement;
  commissionAmount?: number;
  commission: number;
  dateFrom?: string;
  dateTo?: string;

  // subtotalAmount?: number;
  // taxAmount?: number;
  // totalAmount?: number;
  // liquidationNumber?: number;
  // status?: string;

  deserialize(input: LiquidationDTO): Liquidation {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.month = input.month || 12;
    this.year = input.year || 1960;
    this.amount = input.amount || 0;
    this.commission = input.commission || 10;
    this.userId = input.userId || '';
    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';
    this.unisonStatementId = input.unisonStatementId || '';
    this.clientStatementId = input.clientStatementId || '';
    this.type = input.type || UsageTypes.DIGITAL;
    this.dateFrom = input.dateFrom || '';
    this.dateTo = input.dateTo || '';
    this.user = new User().deserialize(input.user);

    if (input.collectionShareLiquidations) {
      this.collectionShareLiquidations = new CollectionShareLiquidation().deserializeArray(
        input.collectionShareLiquidations,
      );
    }

    if (input.unisonStatement) {
      this.unisonStatement = new Statement().deserialize(input.unisonStatement);
    }
    if (input.clientStatement) {
      this.clientStatement = new Statement().deserialize(input.clientStatement);
    }

    // this.commissionAmount = input.commissionAmount;
    // this.subtotalAmount = input.subtotalAmount;
    // this.taxAmount = input.taxAmount;
    // this.totalAmount = input.totalAmount;
    // this.liquidationNumber = input.liquidationNumber;
    // this.status = input.status;

    return this;
  }

  deserializeArray(inputArray: Array<LiquidationDTO>): Array<Liquidation> {
    const liquidations: Array<Liquidation> = inputArray.map((input) =>
      new Liquidation().deserialize(input),
    );

    return liquidations;
  }

  clone(): Liquidation {
    const clonedInstance: Liquidation = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
