import { RoyaltiesSummaryI } from 'app/shared/interfaces';

export const ROYALTIES_LAST_QUARTER_SUMMARY: RoyaltiesSummaryI = {
  periodStart: '01/05/2021',
  periodEnd: '01/09/2021',
  grossDistribution: 100000,
  totalAdditions: 90000,
  totalDeductions: 10000,
  netDistribution: 80000,
  royaltiesInternational: 75000,
  royaltiesDomestic: 25000,
  royaltiesTotal: 100000,
};
