import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Recording, RecordingDTO } from 'app/shared/models';
import { RECORDINGS_WORK_MOCK } from 'app/shared/mocks';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class RecordingService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getRecordingsFromWork(workId: string): Promise<Array<Recording>> {
    let recordingsFetched: Array<Recording> = [];
    if (environment.useMocks) {
      recordingsFetched = new Recording().deserializeArray(RECORDINGS_WORK_MOCK);
    } else {
      const url = `${this.apiUrl}/composition/${workId}/recording/`;
      const response = await this.http
        .get<{ recordings: Array<RecordingDTO> }>(url, httpOptions)
        .toPromise();
      recordingsFetched = new Recording().deserializeArray(response.recordings);
    }

    return recordingsFetched;
  }

  async getRecording(recordingId: string): Promise<Recording> {
    let recordingFetched: Recording;
    if (environment.useMocks) {
      const recordingMock = RECORDINGS_WORK_MOCK.filter(
        (recording: RecordingDTO) => recording.id === recordingId,
      );
      recordingFetched = new Recording().deserialize(recordingMock[0]);
    } else {
      const url = `${this.apiUrl}/recording/${recordingId}/`;
      const response = await this.http.get<{ recording: RecordingDTO }>(url).toPromise();
      recordingFetched = new Recording().deserialize(response.recording);
    }
    return recordingFetched;
  }

  async addRecordingToWork(workId: string, recording: Recording): Promise<Recording> {
    const url = `${this.apiUrl}/composition/${workId}/recording/`;
    const response = await this.http.post<{ recording: RecordingDTO }>(url, recording).toPromise();
    return new Recording().deserialize(response.recording);
  }

  async updateRecording(recording: Recording): Promise<Recording> {
    const url = `${this.apiUrl}/recording/${recording.id}/`;
    const response = await this.http.put<{ recording: RecordingDTO }>(url, recording).toPromise();
    return new Recording().deserialize(response.recording);
  }

  async deleteRecordingFromWork(workId: string, recordingId: string): Promise<void> {
    const url = `${this.apiUrl}/composition/${workId}/recording/${recordingId}`;
    await this.http.delete(url).toPromise();
  }
}
