import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { TerritoriesAPIResponse } from 'app/shared/interfaces';
import { Territory } from 'app/shared/models';
import { TERRITORIES_MOCK } from '../mocks';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getCountries(offset = 0, limit = 999999, country = 'country'): Promise<Array<Territory>> {
    let countriesFetched: Array<Territory> = [];
    // const country = 'country';
    const filterCountry = `?type=${country}`;
    const filters = `&offset=${offset}&limit=${limit}`;

    const url = `${this.apiUrl}/territory${filterCountry}${filters}`;
    try {
      const response: TerritoriesAPIResponse = await this.http
        .get<TerritoriesAPIResponse>(url)
        .toPromise();
      countriesFetched = new Territory().deserializeArray(response.territories);
    } catch (error) {
      // console.log("UserService -> error", error)
    }

    return countriesFetched;
  }

  async getTerritories(offset = 0, limit = 9999): Promise<Territory[]> {
    let territoriesFetched: Territory[] = [];
    if (environment.useMocks) {
      territoriesFetched = new Territory().deserializeArray(TERRITORIES_MOCK.territories);
    } else {
      const filters = `?offset=${offset}&limit=${limit}`;
      const url = `${this.apiUrl}/territory/${filters}`;
      const response: TerritoriesAPIResponse = await this.http
        .get<TerritoriesAPIResponse>(url)
        .toPromise();
      territoriesFetched = new Territory().deserializeArray(response.territories);
    }
    return territoriesFetched;
  }
}
