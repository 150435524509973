import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Liquidation, LiquidationDTO, StatementStatus, UsageTypes } from 'app/shared/models';
import { InfoResponseAPI } from 'app/shared/interfaces';
import { LIQUIDATIONS_MOCK, LIQUIDATIONS_MOCK_PART } from 'app/shared/mocks';

export interface LiquidationsResponse {
  liquidations: Liquidation[];
  info: InfoResponseAPI;
}

export interface LiquidationsFilterI {
  status?: StatementStatus | '';
  type?: UsageTypes | '';
  from?: string;
  to?: string;
}

@Injectable()
export class LiquidationService {
  readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  private readonly liquidationSubject = new BehaviorSubject<Liquidation>(null);
  readonly currentLiquidation$ = this.liquidationSubject.asObservable();

  get currentLiquidation(): Liquidation {
    return this.liquidationSubject.getValue();
  }

  set currentLiquidation(liquidation: Liquidation) {
    this.liquidationSubject.next(liquidation);
  }

  async getLiquidations(
    offset: number,
    limit: number,
    filters?: LiquidationsFilterI,
  ): Promise<LiquidationsResponse> {
    // eslint-disable-next-line max-len
    // const queryString = `?offset=${offset}&limit=${limit}&type=${filters?.type}&status=${filters?.status}&from=${filters?.from}&to=${filters?.to}`;
    const queryString = `?offset=${offset}&limit=${limit}`;

    if (environment.useMocks) {
      const allLiquidations = new Liquidation().deserializeArray(LIQUIDATIONS_MOCK);

      const liquidationsFetched = allLiquidations.slice(offset, offset + limit);

      return {
        liquidations: liquidationsFetched,
        info: {
          count: allLiquidations.length,
          offset: offset.toString(),
          limit: limit.toString(),
        },
      };
    }
    const url = `${this.apiUrl}/liquidation/me/${queryString}`;

    const { liquidations: LiquidationsApi, info } = await this.http
      .get<{ liquidations: Array<LiquidationDTO>; info: InfoResponseAPI }>(url)
      .toPromise();

    return {
      liquidations: new Liquidation().deserializeArray(LiquidationsApi),
      info,
    };
  }

  async getLiquidation(liquidationId: string): Promise<Liquidation> {
    if (environment.useMocks) {
      const liquidationMock = LIQUIDATIONS_MOCK_PART.filter(
        (liquidation: LiquidationDTO) => liquidation.id === liquidationId,
      );
      return new Liquidation().deserialize(liquidationMock[0]);
    }

    const url = `${this.apiUrl}/liquidation/${liquidationId}/`;

    const { liquidation: liquidationApi } = await this.http
      .get<{ liquidation: LiquidationDTO }>(url)
      .toPromise();

    return new Liquidation().deserialize(liquidationApi);
  }

  async deleteLiquidation(liquidationdId: string): Promise<void> {
    const url = `${this.apiUrl}/liquidation/${liquidationdId}/`;

    await this.http.delete<void>(url).toPromise();
  }

  // updateLiquidationStatus(id, data) {
  //   const url = `${this.apiUrl}/liquidation/${id}/status`;
  //   return this.http.put(url, data).pipe(
  //     tap(() => this.log(`updated status from liquidation with id = ${id}`)),
  //     catchError(this.handleError),
  //   );
  // }

  // generateLiquidations() {
  //   const url = `${this.apiUrl}/liquidation/emit/`;
  //   // Canviar per post
  //   return this.http.post(url, null).pipe(
  //     tap(() => this.log('generated liquidations')),
  //     catchError(this.handleError),
  //   );
  // }
}
