/* eslint-disable */
import { Injectable } from '@angular/core';

import { RightHolder, Territory } from 'app/shared/models';
import { LANGUAGES } from 'app/shared/mocks';
import { CountryService } from './country.service';

const recordDescriptions = {
  HDR: 'Transmission Header',
  GRH: 'Group Header',
  GRT: 'Group Trailer',
  TRL: 'Transmission Trailer',
  NWR: 'New Works Registration',
  REV: 'Revised Registration',
  ISW: 'Notification of ISWC assigned to a work',
  EXC: 'Existing work which is in conflict with a work registration',
  ACK: 'Acknowledgment of Transaction',
  SPU: 'Publisher Controlled By Submitter',
  OPU: 'Other Publisher',
  SPT: 'Publisher Territory of Control',
  SWR: 'Writer Controlled By Submitter',
  OWR: 'Other Writer',
  SWT: 'Writer Territory of Control',
  PWR: 'Publisher For Writer',
  ALT: 'Alternate Title',
  PER: 'Performing Artist',
  REC: 'Recording Detail',
  MSG: 'Message',
};

export interface TransactionInfo {
  title: string;
  submitterWorkN: string;
  iswc?: string;
}

interface LabelType {
  label: string;
  description: string;
}

export interface CwrError {
  type: string;
  message: string;
  line: number;
  lineContent: string;
  transactionInfo?: TransactionInfo;
  transactionsInfo?: Array<TransactionInfo>;
  originalValue?: string;
  newValue?: string;
  recordType?: LabelType;
  groupType?: LabelType;
}

@Injectable()
export class CwrParserService {
  tagName: string;
  file: any;
  cwrJSON: any;
  errors: Array<CwrError>;
  lastRecord: string;
  selectTransaction: boolean;
  groupIndex: number;
  transactionIndex: number;
  usedGroupTypes: string[];
  totalTransactions: number;
  recordsInGroup: number;
  totalRecords: number;
  durationRegex = new RegExp('^(?:([01]?\\d|2[0-3]):([0-5]?\\d):)?([0-5]?\\d)$');
  dateRegex = new RegExp('^([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))$');
  dateTimeRegex = new RegExp(
    '^([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))T(?:([01]?\\d|2[0-3]):([0-5]?\\d):)?([0-5]?\\d)$',
  );

  iswcRegex = new RegExp('T\\d{10}$');
  publishersIPn: any;
  lastWriterIpn: string;
  lastPubIpn: string;
  lastPubType: string;
  currentSeqN: number;
  lastChain: number;
  chainType: string;
  groupType: string;
  index: number;
  transactionsToReject: any[];
  groupsToReject: number[];
  rejectFile: boolean;
  transactionIsMOD: boolean;
  transactionIsORI: boolean;
  foundMODType: boolean;
  foundXWRType: boolean;
  foundCAACType: boolean;
  catalogs: any[];
  transactionInfo: TransactionInfo;
  transactionsInfo: Array<TransactionInfo>;
  territories: Array<Territory>;
  rightHolders: any;
  performers: any;
  performersIpis: any;
  performersBases: any;
  ipis: any;
  societies: any;

  constructor(private countryService: CountryService) {}

  static valueInArray(value: any, array: any[]): boolean {
    for (let i = 0; i < array.length; i += 1) {
      if (value === array[i]) {
        return true;
      }
    }
    return false;
  }

  static tisInArrayObjects(value: any, array: Array<Territory>): boolean {
    for (let i = 0; i < array.length; i += 1) {
      if (value === array[i].tis) {
        return true;
      }
    }
    return false;
  }

  static valueInArrayObjects(value: any, array: any[]): boolean {
    for (let i = 0; i < array.length; i += 1) {
      if (value === array[i].value) {
        return true;
      }
    }
    return false;
  }

  async createJSONFromFile(files: FileList, chunk: number): Promise<any> {
    if (!files || files.length <= 0) {
      return false;
    }

    const territories = await this.countryService.getTerritories();
    this.territories = territories;

    const file: File = files.item(0);

    return new Promise((resolve, reject) => {
      this.tagName = file.name;
      const reader: FileReader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        this.file = reader.result;
        try {
          this.parseFile();
          this.splitByCompositions(chunk);
          resolve({
            cwrJSON: this.cwrJSON,
            catalogs: this.catalogs,
            errors: this.errors,
            rightHolders: Object.values(this.rightHolders),
            performers: Object.values(this.performers),
            societies: Object.values(this.societies),
          });
        } catch (e) {
          reject(e);
        }
      };
      reader.onerror = () => reject();
    });
  }

  copyNTransactions(groupI: number, transactionI: number, nTransactions: number) {
    const group = this.cwrJSON.transmission.groups[groupI];
    const transactions = [];
    let transactionsSize = 0;
    for (let i = 0; i < nTransactions; i += 1) {
      if (transactionI + i < group.transactions.length) {
        const transaction = group.transactions[transactionI + i];
        transactionsSize += transaction.length;
        transactions.push(transaction);
      }
    }

    return {
      tag: {
        year: this.cwrJSON.tag.year,
        sequence_n: this.cwrJSON.tag.sequence_n,
        sender: this.cwrJSON.tag.sender,
        receiver: this.cwrJSON.tag.receiver,
        version: this.cwrJSON.tag.version,
      },
      transmission: {
        groups: [
          {
            group_header: {
              record_type: group.group_header.record_type,
              transaction_type: group.group_header.transaction_type,
              group_id: group.group_header.group_id,
              version_number: group.group_header.version_number,
              batch_request_id: group.group_header.batch_request_id,
            },
            group_trailer: {
              record_type: group.group_trailer.record_type,
              group_id: 1,
              transaction_count: transactions.length,
              record_count: transactionsSize,
            },
            transactions,
          },
        ],
        trailer: {
          record_type: this.cwrJSON.transmission.trailer.record_type,
          group_count: 1,
          transaction_count: transactions.length,
          record_count: transactionsSize,
        },
        header: {
          record_type: this.cwrJSON.transmission.header.record_type,
          sender_type: this.cwrJSON.transmission.header.sender_type,
          sender_id: this.cwrJSON.transmission.header.sender_id,
          sender_name: this.cwrJSON.transmission.header.sender_name,
          edi_standard: this.cwrJSON.transmission.header.edi_standard,
          creation_date_time: this.cwrJSON.transmission.header.creation_date_time,
          transmission_date: this.cwrJSON.transmission.header.transmission_date,
          character_set: this.cwrJSON.transmission.header.character_set,
        },
      },
    };
  }

  splitByCompositions(chunk = 1) {
    this.catalogs = [];
    const { groups } = this.cwrJSON.transmission;

    for (let i = 0; i < groups.length; i += 1) {
      for (let j = 0; j < groups[i].transactions.length; j += chunk) {
        this.catalogs.push(this.copyNTransactions(i, j, chunk));
      }
    }
  }

  parseFile() {
    this.initVars();
    this.processTagName();
    this.parseByRows();
    this.applyRejections();
  }

  initVars() {
    this.errors = [];
    this.lastRecord = null;
    this.selectTransaction = true;
    this.groupIndex = 0;
    this.usedGroupTypes = [];
    this.totalTransactions = 0;
    this.recordsInGroup = 0;
    this.totalRecords = 0;
    this.publishersIPn = {};
    this.lastChain = 0;
    this.index = 0;
    this.transactionsToReject = [];
    this.groupsToReject = [];
    this.transactionsInfo = [];
    this.rejectFile = false;
    this.foundCAACType = true;
    this.foundXWRType = true;
    this.societies = {};
    this.rightHolders = {};
    this.performers = {};
    this.performersBases = {};
    this.performersIpis = {};
    this.ipis = {};
  }

  processTagName() {
    if (this.tagName.length !== 19) {
      this.errors.push({
        message: 'File name does not follow CWR standards',
        type: 'ER',
        line: this.index,
        lineContent: this.tagName,
      });
      this.rejectFile = true;
    }

    this.cwrJSON = {
      tag: {
        year: `20${this.tagName.substr(2, 2)}`,
        sequence_n: this.tagName.substr(4, 4),
        sender: this.tagName.substr(8, 3),
        receiver: this.tagName.substr(12, 3),
        version: `${this.tagName.substr(17, 1)}.${this.tagName.substr(18, 1)}`,
      },
      transmission: {
        groups: [],
        trailer: {},
        header: {},
      },
    };
  }

  parseByRows() {
    const rows: string[] = this.file.split('\n');
    for (this.index = 1; this.index <= rows.length; this.index += 1) {
      const record = rows[this.index - 1].substr(0, 3);
      switch (record) {
        case 'HDR':
          this.processHDRRecord(rows[this.index - 1]);
          break;
        case 'GRH':
          this.processGRHRecord(rows[this.index - 1]);
          break;
        case 'GRT':
          this.processGRTRecord(rows[this.index - 1]);
          break;
        case 'TRL':
          this.processTRLRecord(rows[this.index - 1]);
          break;
        case 'NWR':
        case 'REV':
        case 'ISW':
        case 'EXC':
          this.processTransactionRecord(rows[this.index - 1]);
          this.selectTransaction = true;
          break;
        case 'ACK':
          this.processACKRecord(rows[this.index - 1]);
          this.selectTransaction = false;
          break;
        case 'SPU':
        case 'OPU':
          this.processXPURecord(rows[this.index - 1]);
          break;
        case 'SPT':
          this.processSPTRecord(rows[this.index - 1]);
          break;
        case 'SWR':
        case 'OWR':
          this.processXWRRecord(rows[this.index - 1]);
          this.foundXWRType = true;
          break;
        case 'SWT':
          this.processSWTRecord(rows[this.index - 1]);
          break;
        case 'PWR':
          this.processPWRRecord(rows[this.index - 1]);
          break;
        case 'ALT':
          this.processALTRecord(rows[this.index - 1]);
          break;
        case 'PER':
          this.processPERRecord(rows[this.index - 1]);
          break;
        case 'REC':
          this.processRECRecord(rows[this.index - 1]);
          break;
        case 'MSG':
          this.processMSGRecord(rows[this.index - 1]);
          break;
        default:
          console.log(record);
          this.recordsInGroup += 1;
          break;
      }
      if (
        this.lastRecord === 'GRH' &&
        !CwrParserService.valueInArray(record, ['NWR', 'REV', 'ISW', 'EXC', 'ACK'])
      ) {
        this.errors.push({
          message: 'GRH must be followed by a transaction',
          type: 'ER',
          line: this.index,
          lineContent: rows[this.index - 1],
        });
        this.rejectFile = true;
      }
      if (record !== '') {
        this.lastRecord = record;
      }
      this.totalRecords += 1;
    }

    if (this.lastRecord !== 'TRL') {
      this.errors.push({
        message: 'Last record must be TRL',
        type: 'ER',
        line: this.index,
        lineContent: rows[this.index - 1],
      });
    }
  }

  applyRejections() {
    let groupsRejected = 0;
    const transactionsForGroupRejected = [];

    for (let i = 0; i < this.cwrJSON.transmission.groups.length; i += 1) {
      transactionsForGroupRejected.push(0);
    }
    this.removeRepeatedGroupRejections();
    this.removeRepeatedTransactionRejections();

    if (this.rejectFile) {
      // Reject File
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw this.errors;
    } else {
      // Reject Groups
      for (let i = 0; i < this.groupsToReject.length; i += 1) {
        const index = this.groupsToReject[i] - groupsRejected;
        this.cwrJSON.transmission.groups.splice(index, 1);
        groupsRejected += 1;
      }

      // Reject Transactions
      for (let i = 0; i < this.transactionsToReject.length; i += 1) {
        if (
          !CwrParserService.valueInArray(this.transactionsToReject[i].group, this.groupsToReject)
        ) {
          let previousGroupsRejected = 0;
          for (let j = 0; j < this.groupsToReject.length; j += 1) {
            if (this.groupsToReject[i] < this.transactionsToReject[i].group) {
              previousGroupsRejected += 1;
            }
          }
          const indexG = this.transactionsToReject[i].group - previousGroupsRejected;
          const indexT =
            this.transactionsToReject[i].transaction -
            transactionsForGroupRejected[this.transactionsToReject[i].group];
          this.cwrJSON.transmission.groups[indexG].transactions.splice(indexT, 1);
          transactionsForGroupRejected[this.transactionsToReject[i].group] += 1;
        }
      }
    }
  }

  removeRepeatedGroupRejections() {
    if (this.groupsToReject.length > 0) {
      let lastGroupToReject = this.groupsToReject[0];

      let i = 1;
      while (i < this.groupsToReject.length) {
        if (lastGroupToReject === this.groupsToReject[i]) {
          this.groupsToReject.splice(i, 1);
        } else {
          lastGroupToReject = this.groupsToReject[i];
          i += 1;
        }
      }
    }
  }

  removeRepeatedTransactionRejections() {
    if (this.transactionsToReject.length > 0) {
      let lastTransactionToReject = this.transactionsToReject[0];

      let i = 1;
      while (i < this.transactionsToReject.length) {
        if (
          lastTransactionToReject.group === this.transactionsToReject[i].group &&
          lastTransactionToReject.transaction === this.transactionsToReject[i].transaction
        ) {
          this.transactionsToReject.splice(i, 1);
        } else {
          lastTransactionToReject = this.transactionsToReject[i];
          i += 1;
        }
      }
    }
  }

  processHDRRecord(record: string) {
    const recordType = record.substr(0, 3);
    const senderType = record.substr(3, 2);
    const senderId = parseInt(record.substr(5, 9), 10);
    const ediStandard = record.substr(59, 5);
    const creationDateTime = `${record.substr(64, 4)}-${record.substr(68, 2)}-${record.substr(
      70,
      2,
    )}T${record.substr(72, 2)}:${record.substr(74, 2)}:${record.substr(76, 2)}`;
    const transmissionDate = `${record.substr(78, 4)}-${record.substr(82, 2)}-${record.substr(
      84,
      2,
    )}`;

    if (this.lastRecord !== null) {
      this.errors.push({
        message: 'HDR must be first record',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }

    this.cwrJSON.transmission.header = {
      record_type: recordType,
      sender_type: senderType,
      sender_id: senderId,
      sender_name: record.substr(14, 45).trim(),
      edi_standard: ediStandard,
      creation_date_time: creationDateTime,
      transmission_date: transmissionDate,
      character_set: record.substr(86).trim().length ? record.substr(86) : null,
    };

    if (recordType !== 'HDR') {
      this.errors.push({
        message: 'Record Type must be equal to HDR',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (!CwrParserService.valueInArray(senderType, ['PB', 'SO', 'WR', 'AA'])) {
      this.errors.push({
        message: 'Sender Type must be equal to PB, SO, WR or AA',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (CwrParserService.valueInArray(senderType, ['PB', 'WR', 'AA']) && Number.isNaN(senderId)) {
      this.errors.push({
        message: 'If Sender Type is equal to PB, WR or AA, sender id must be entered',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (senderType === 'SO' && (senderId < 0 || senderId > 310 || Number.isNaN(senderId))) {
      this.errors.push({
        message:
          'If Sender Type is equal to SO, Sender ID must be entered and must match an entry in its table',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
    }
    if (ediStandard !== '01.10') {
      this.errors.push({
        message: 'EDI Standard Version Number must be equal to "01.10"',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (!this.dateTimeRegex.test(creationDateTime)) {
      this.errors.push({
        message: 'Creation Date must be a valid date',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (!this.dateRegex.test(transmissionDate)) {
      this.errors.push({
        message: 'Transmission Date must be a valid date',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
  }

  processGRHRecord(record: string) {
    let rejectGroup = false;
    const transactionType = record.substr(3, 3);
    const groupId = parseInt(record.substr(6, 5), 10);
    const versionNumber = record.substr(11, 5);
    this.transactionsInfo = [];

    if (this.lastRecord !== 'HDR' && this.lastRecord !== 'GRT') {
      this.errors.push({
        message: 'GRH must come after either HDR or GRT',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }

    if (!CwrParserService.valueInArray(transactionType, ['NWR', 'REV', 'ISW', 'EXC', 'ACK'])) {
      this.errors.push({
        message: 'Transaction type must match an entry in transaction type table',
        type: 'GR',
        line: this.index,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: transactionType, description: recordDescriptions[transactionType] },
      });
      rejectGroup = true;
    }
    if (this.groupIndex + 1 !== groupId) {
      this.errors.push({
        message: 'Group ID must be entered, start at 1, and increment by 1 sequentially',
        type: 'GR',
        line: this.index,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: transactionType, description: recordDescriptions[transactionType] },
      });
      rejectGroup = true;
    }
    if (versionNumber !== '02.10') {
      this.errors.push({
        message: 'Version number must be "02.10"',
        type: 'GR',
        line: this.index,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: transactionType, description: recordDescriptions[transactionType] },
      });
      rejectGroup = true;
    }
    // TODO: check different kinds of ack in different groups
    if (
      CwrParserService.valueInArray(transactionType, this.usedGroupTypes) &&
      transactionType !== 'ACK'
    ) {
      this.errors.push({
        message: 'Each transaction type can only be used once',
        type: 'GR',
        line: this.index,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: transactionType, description: recordDescriptions[transactionType] },
      });
      rejectGroup = true;
    }

    this.cwrJSON.transmission.groups.push({
      group_header: {
        record_type: record.substr(0, 3),
        transaction_type: transactionType,
        group_id: groupId,
        version_number: versionNumber,
        batch_request_id: record.length > 16 ? parseInt(record.substr(16, 10), 10) : null,
      },
      group_trailer: {},
      transactions: [],
    });

    if (rejectGroup) {
      this.groupsToReject.push(this.groupIndex);
    }

    this.recordsInGroup = 1;
    this.transactionIndex = -1;
    this.groupType = transactionType;
    this.usedGroupTypes.push(transactionType);
  }

  processGRTRecord(record: string) {
    let rejectGroup = false;
    let rejectLastTransaction = false;
    this.cwrJSON.transmission.groups[this.groupIndex].group_trailer = {
      record_type: record.substr(0, 3),
      group_id: parseInt(record.substr(3, 5), 10),
      transaction_count: parseInt(record.substr(8, 8), 10),
      record_count: parseInt(record.substr(16, 8), 10),
    };

    this.recordsInGroup += 1;
    if (
      this.cwrJSON.transmission.groups[this.groupIndex].group_trailer.group_id !==
      this.groupIndex + 1
    ) {
      this.errors.push({
        message: 'Group ID must be equal to the previous GRH record',
        type: 'GR',
        line: this.index,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: this.groupType, description: recordDescriptions[this.groupType] },
      });
      rejectGroup = true;
    }
    if (
      this.cwrJSON.transmission.groups[this.groupIndex].group_trailer.transaction_count !==
      this.transactionIndex + 1
    ) {
      this.errors.push({
        message: 'Transaction count must be equal to the number of transactions within the group',
        type: 'GR',
        line: this.index,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: this.groupType, description: recordDescriptions[this.groupType] },
      });
      rejectGroup = true;
    }
    if (
      this.cwrJSON.transmission.groups[this.groupIndex].group_trailer.record_count !==
      this.recordsInGroup
    ) {
      this.errors.push({
        message:
          'Record count must be equal to the number of records within the group including GRH and GRT',
        type: 'GR',
        line: this.index,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: this.groupType, description: recordDescriptions[this.groupType] },
      });
      rejectGroup = true;
    }
    if (this.transactionIsMOD && !this.foundMODType) {
      this.errors.push({
        message:
          'If Version Type is "MOD" at least one SWR or OWR must be "AR", "AD", "SR", "SA" or "TR"',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectLastTransaction = true;
    }
    if (!this.foundXWRType) {
      this.errors.push({
        message: 'A transaction must contain at least one writer record, SWR, or OWR',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectLastTransaction = true;
    }
    if (!this.foundCAACType) {
      this.errors.push({
        message: 'There must be at least one writer ("CA", "A", "C") in a work',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectLastTransaction = true;
    }
    if (this.transactionIsORI && this.foundMODType) {
      this.errors.push({
        message:
          'If Version Type is "ORI" there can not be a SWR or OWR with "AR", "AD", SR", "SA", "TR"',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectLastTransaction = true;
    }

    if (rejectGroup) {
      this.groupsToReject.push(this.groupIndex);
    }
    if (rejectLastTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }
    this.transactionIsMOD = false;
    this.transactionIsORI = false;
    this.foundMODType = false;
    this.foundXWRType = true;
    this.foundCAACType = true;
    this.groupIndex += 1;
  }

  processTRLRecord(record: string) {
    if (this.lastRecord !== 'GRT') {
      this.errors.push({
        message: 'TRL must come after GRT',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }

    this.cwrJSON.transmission.trailer = {
      record_type: record.substr(0, 3),
      group_count: parseInt(record.substr(3, 5), 10),
      transaction_count: parseInt(record.substr(8, 8), 10),
      record_count: parseInt(record.substr(16, 8), 10),
    };

    if (this.groupIndex !== this.cwrJSON.transmission.trailer.group_count) {
      this.errors.push({
        message: 'Group Count must be equal to the number of groups included in this file',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (this.totalTransactions !== this.cwrJSON.transmission.trailer.transaction_count) {
      this.errors.push({
        message:
          'Transaction Count must be equal to the number of transactions included in this file',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (this.totalRecords + 1 !== this.cwrJSON.transmission.trailer.record_count) {
      this.errors.push({
        message: 'Record Count must be equal to the number of records included in this file',
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
  }

  processTransactionRecord(record: string) {
    let rejectLastTransaction = false;
    if (this.transactionIsMOD && !this.foundMODType) {
      this.errors.push({
        message:
          'If Version Type is "MOD" at least one SWR or OWR must be "AR", "AD", "SR", "SA" or "TR"',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectLastTransaction = true;
    }
    if (!this.foundXWRType) {
      this.errors.push({
        message: 'A transaction must contain at least one writer record, SWR, or OWR',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectLastTransaction = true;
    }
    if (!this.foundCAACType) {
      this.errors.push({
        message: 'There must be at least one writer ("CA", "A", "C") in a work',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectLastTransaction = true;
    }
    if (this.transactionIsORI && this.foundMODType) {
      this.errors.push({
        message:
          'If Version Type is "ORI" there can not be a SWR or OWR with "AR", "AD", SR", "SA", "TR"',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectLastTransaction = true;
    }

    let rejectTransaction = false;
    let rejectGroup = false;
    const recordType = record.substr(0, 3);
    const languageCode = record.substr(79, 2).trim().length ? record.substr(79, 2) : null;
    const title = record.substr(19, 60).trim();
    const musicalWorkDistributionCategory = record.substr(126, 3);
    const versionType = record.substr(142, 3);
    const musicArrangement = record.substr(148, 3).trim().length ? record.substr(148, 3) : null;
    const lyricAdaptation = record.substr(151, 3).trim().length ? record.substr(151, 3) : null;
    const compositeComponentCount = record.substr(197, 3).trim().length
      ? parseInt(record.substr(197, 3), 10)
      : 0;
    const submitterWorkN = record.substr(81, 14).trim();
    let iswc = record.substr(95, 11).trim().length ? record.substr(95, 11) : null;
    let recordedIndicator = record.substr(135, 1);
    let textMusicRelationship = record.substr(136, 3).trim().length ? record.substr(136, 3) : null;
    let compositeType = record.substr(139, 3).trim().length ? record.substr(139, 3) : null;
    let excerptType = record.substr(145, 3).trim().length ? record.substr(145, 3) : null;
    let grandRightsInd = record.substr(196, 1).trim().length ? record.substr(196, 1) === 'Y' : null;
    let cwrWorkType = record.substr(194, 2).trim().length ? record.substr(194, 2) : null;
    let copyrightDate = record.substr(106, 8).trim().length
      ? `${record.substr(106, 4)}-${record.substr(110, 2)}-${record.substr(112, 2)}`
      : null;
    let duration = record.substr(129, 6).trim().length
      ? `${record.substr(129, 2)}:${record.substr(131, 2)}:${record.substr(133, 2)}`
      : null;
    this.transactionInfo = { title, iswc, submitterWorkN };
    this.transactionsInfo.push(this.transactionInfo);

    if (this.selectTransaction && recordType !== this.groupType) {
      this.errors.push({
        message:
          'The Transaction Record Type must be the same as the Transaction Type of the group',
        type: 'GR',
        line: this.groupIndex,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: this.groupType, description: recordDescriptions[this.groupType] },
      });
      rejectGroup = true;
    }
    if (this.lastRecord === 'GRT' || this.lastRecord === 'HDR' || this.lastRecord === 'TRL') {
      this.errors.push({
        message: `${record.substr(0, 3)} can't come after ${this.lastRecord}`,
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (title === '') {
      this.errors.push({
        message: 'Work Title must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (languageCode !== null && !CwrParserService.valueInArrayObjects(languageCode, LANGUAGES)) {
      this.errors.push({
        message: 'Language Code, if entered, must match an entry in the Language Code Table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (iswc !== null && !this.iswcRegex.test(iswc)) {
      this.errors.push({
        message: 'If ISWC is entered, it must be a valid ISWC',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: iswc,
        newValue: '',
      });
      iswc = null;
    }
    if (!submitterWorkN || submitterWorkN === '') {
      this.errors.push({
        message:
          'Submitter Work Number must be entered and must be unique for the party submitting the file',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
    }
    if (copyrightDate !== '0000-00-00' && !this.dateRegex.test(copyrightDate)) {
      this.errors.push({
        message: 'Copyright Date must be a valid date',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: copyrightDate,
        newValue: '0000-00-00',
      });
      copyrightDate = '0000-00-00';
    }
    if (
      !CwrParserService.valueInArray(musicalWorkDistributionCategory, ['JAZ', 'POP', 'SER', 'UNC'])
    ) {
      this.errors.push({
        message:
          'Musical Work Distribution Category must be entered and match an entry in its table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      musicalWorkDistributionCategory === 'SER' &&
      (!this.durationRegex.test(duration) || duration === '00:00:00')
    ) {
      this.errors.push({
        message:
          'If Musical Work Distribution Category is "SER", duration must be valid and greater than 0',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      musicalWorkDistributionCategory !== 'SER' &&
      duration !== null &&
      !this.durationRegex.test(duration)
    ) {
      this.errors.push({
        message:
          'If Musical Work Distribution Category is not "SER", and duration is entered it must be valid',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: duration,
        newValue: '',
      });
      duration = null;
    }
    if (!CwrParserService.valueInArray(recordedIndicator, ['Y', 'N', 'U'])) {
      this.errors.push({
        message: 'Recorded Indicator must be equal to "Y", "N", or "U"',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: recordedIndicator,
        newValue: 'U',
      });
      recordedIndicator = 'U';
    }
    if (
      textMusicRelationship !== null &&
      !CwrParserService.valueInArray(textMusicRelationship, ['MUS', 'MTX', 'TXT', 'MTN'])
    ) {
      this.errors.push({
        message: 'If Text Music Relationship is entered, it must match an entry in its table',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: textMusicRelationship,
        newValue: '',
      });
      textMusicRelationship = null;
    }
    if (
      compositeType !== null &&
      !CwrParserService.valueInArray(compositeType, ['COS', 'MED', 'POT', 'UCO'])
    ) {
      this.errors.push({
        message: 'If Composite Type is entered, it must match an entry on its table',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: compositeType,
        newValue: '',
      });
      compositeType = null;
    }
    if (!CwrParserService.valueInArray(versionType, ['MOD', 'ORI'])) {
      this.errors.push({
        message: 'Version Type must be entered and match an entry in its table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (excerptType !== null && !CwrParserService.valueInArray(excerptType, ['MOV', 'UEX'])) {
      this.errors.push({
        message: 'If Excerpt Type is entered, it must match an entry on its table',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: excerptType,
        newValue: '',
      });
      excerptType = null;
    }
    if (
      versionType === 'MOD' &&
      !CwrParserService.valueInArray(musicArrangement, ['NEW', 'ARR', 'ADM', 'UNS', 'ORI'])
    ) {
      this.errors.push({
        message: 'If Version Type is "MOD", Music Arrangement must match an entry on its table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      versionType === 'MOD' &&
      (lyricAdaptation === null ||
        !CwrParserService.valueInArray(lyricAdaptation, [
          'NEW',
          'MOD',
          'NON',
          'ORI',
          'REP',
          'ADL',
          'UNS',
          'TRA',
        ]))
    ) {
      this.errors.push({
        message: 'If Version Type is "MOD", Lyric adaptation must match an entry on its table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      grandRightsInd !== null &&
      !CwrParserService.valueInArray(record.substr(196, 1), ['Y', 'N'])
    ) {
      this.errors.push({
        message: 'If entered, Grand Rights Ind. must be equal to "Y" or "N"',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: record.substr(196, 1),
        newValue: '',
      });
      grandRightsInd = null;
    }
    if (
      cwrWorkType !== null &&
      !CwrParserService.valueInArray(cwrWorkType, [
        'TA',
        'AC',
        'AR',
        'AL',
        'AM',
        'BD',
        'BL',
        'CD',
        'CL',
        'CC',
        'CT',
        'DN',
        'FM',
        'FK',
        'BG',
        'SG',
        'JZ',
        'JG',
        'LN',
        'LA',
        'NA',
        'OP',
        'PK',
        'PP',
        'RP',
        'RK',
        'RB',
        'SD',
        'Sy',
      ])
    ) {
      this.errors.push({
        message: 'When entered, CWR Work Type must match an entry in the CWR Work Type table',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: cwrWorkType,
        newValue: '',
      });
      cwrWorkType = null;
    }
    if (compositeType !== null && compositeComponentCount === 0) {
      this.errors.push({
        message: 'If Composite Type is entered, Composite Component Count must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (compositeType === null && compositeComponentCount !== 0) {
      this.errors.push({
        message: 'If Composite Component Count is entered, Composite Type must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (compositeComponentCount !== 0 && compositeComponentCount <= 1) {
      this.errors.push({
        message: 'If entered, Composite Component Count must be numeric and must be greater than 1',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      musicArrangement !== null &&
      !CwrParserService.valueInArray(musicArrangement, [
        'NEW',
        'ARR',
        'ADM',
        'UNS',
        'ORI',
        'JAZ',
        'POP',
        'SER',
        'UNC',
      ])
    ) {
      this.errors.push({
        message: 'If entered, Music Arrangement must match an entry in its table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      lyricAdaptation !== null &&
      !CwrParserService.valueInArray(lyricAdaptation, [
        'NEW',
        'MOD',
        'NON',
        'ORI',
        'REP',
        'ADL',
        'UNS',
        'TRA',
      ])
    ) {
      this.errors.push({
        message: 'If entered, Lyric Adaptation must match an entry in its table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (recordType === 'EXC' && this.groupType === 'ACK' && !this.selectTransaction) {
      this.errors.push({
        message:
          'The EXC transaction must follow an NWR or REV transaction within the ACK transaction',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      !this.selectTransaction &&
      !CwrParserService.valueInArray(recordType, ['NWR', 'REV', 'AGR'])
    ) {
      this.errors.push({
        message: 'The ACK transaction must be followed by one of NWR, REV or AGR transactions',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }

    const recordObject = {
      record_type: recordType,
      transaction_sequence_n: parseInt(record.substr(3, 8), 10),
      record_sequence_n: parseInt(record.substr(11, 8), 10),
      title,
      language_code: languageCode,
      submitter_work_n: submitterWorkN,
      iswc,
      copyright_date: copyrightDate,
      copyright_number: record.substr(114, 12).trim().length ? record.substr(114, 12) : null,
      musical_work_distribution_category: musicalWorkDistributionCategory,
      duration,
      recorded_indicator: recordedIndicator,
      text_music_relationship: textMusicRelationship,
      composite_type: compositeType,
      version_type: versionType,
      excerpt_type: excerptType,
      music_arrangement: musicArrangement,
      lyric_adaptation: lyricAdaptation,
      contact_name: record.substr(154, 30).trim().length ? record.substr(154, 30).trim() : null,
      contact_id: record.substr(184, 3).trim().length ? record.substr(184, 3).trim() : null,
      work_type: cwrWorkType,
      grand_rights_indicator: grandRightsInd,
      composite_component_count: compositeComponentCount,
      date_publication_printed_edition: record.substr(200, 8).trim().length
        ? `${record.substr(200, 4)}-${record.substr(204, 2)}-${record.substr(206, 2)}`
        : null,
      exceptional_clause: record.substr(208, 1).trim().length ? record.substr(208, 1) : null,
      opus_number: record.substr(209, 25).trim().length ? record.substr(209, 25).trim() : null,
      catalogue_number: record.substr(234, 25).trim().length ? record.substr(234, 25).trim() : null,
      priority_flag: record.substr(259, 1).trim().length ? record.substr(259, 1) : null,
    };

    if (rejectLastTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }

    if (!this.selectTransaction) {
      this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push(
        recordObject,
      );
    } else {
      this.cwrJSON.transmission.groups[this.groupIndex].transactions.push([recordObject]);
      this.transactionIndex += 1;
      this.totalTransactions += 1;
    }

    this.foundMODType = false;
    this.foundXWRType = false;
    this.foundCAACType = false;
    this.transactionIsMOD = versionType === 'MOD';
    this.transactionIsORI = versionType === 'ORI';
    this.lastRecord = record.substr(0, 3);
    this.lastChain = 0;
    this.recordsInGroup += 1;

    if (rejectTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }
    if (rejectGroup) {
      this.groupsToReject.push(this.groupIndex);
    }
  }

  processACKRecord(record: string) {
    let rejectTransaction = false;
    let rejectGroup = false;
    const recordType = record.substr(0, 3);
    const processingDate = `${record.substr(149, 4)}-${record.substr(153, 2)}-${record.substr(
      155,
      2,
    )}`;
    const transactionStatus = record.substr(157, 2);
    const originalTransactionType = record.substr(46, 3);
    const creationTitle = record.substr(49, 60).trim().length ? record.substr(49, 60).trim() : null;
    const creationDateTime = `${record.substr(19, 4)}-${record.substr(23, 2)}-${record.substr(
      25,
      2,
    )}T${record.substr(27, 2)}:${record.substr(29, 2)}:${record.substr(31, 2)}`;
    this.transactionInfo = { title: creationTitle, iswc: null, submitterWorkN: null };
    this.transactionsInfo.push(this.transactionInfo);

    if (this.lastRecord === 'GRT' || this.lastRecord === 'HDR' || this.lastRecord === 'TRL') {
      this.errors.push({
        message: `${record.substr(0, 3)} can't come after ${this.lastRecord}`,
        type: 'ER',
        line: this.index,
        lineContent: record,
      });
      this.rejectFile = true;
    }
    if (!this.selectTransaction) {
      this.errors.push({
        message: 'The ACK transaction must be followed by one of NWR, REV or AGR transactions',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (recordType !== this.groupType) {
      this.errors.push({
        message:
          'The Transaction Type of the preceding GRH must be ACK for submissions from a society',
        type: 'GR',
        line: this.index,
        lineContent: record,
        transactionsInfo: this.transactionsInfo,
        groupType: { label: this.groupType, description: recordDescriptions[this.groupType] },
      });
      rejectGroup = true;
    }
    if (this.cwrJSON.transmission.header.creation_date_time !== creationDateTime) {
      this.errors.push({
        message:
          'The combination of creation date and time must match the same fields on the HDR record',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (!this.dateRegex.test(processingDate)) {
      this.errors.push({
        message: 'Processing Date must be a valid date',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      !CwrParserService.valueInArray(transactionStatus, [
        'CO',
        'DU',
        'RA',
        'AS',
        'AC',
        'RJ',
        'NP',
        'RC',
      ])
    ) {
      this.errors.push({
        message: 'Transaction Status must match an entry in the Transaction Status table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      CwrParserService.valueInArray(originalTransactionType, ['NWR', 'REV']) &&
      creationTitle === null
    ) {
      this.errors.push({
        message:
          'Creation Title is required if the ACL is in response to an NWR or REV transaction',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }

    this.cwrJSON.transmission.groups[this.groupIndex].transactions.push([
      {
        record_type: recordType,
        transaction_sequence_n: parseInt(record.substr(3, 8), 10),
        record_sequence_n: parseInt(record.substr(11, 8), 10),
        creation_date_time: creationDateTime,
        original_group_id: parseInt(record.substr(6, 5), 10),
        original_transaction_sequence_n: parseInt(record.substr(38, 8), 10),
        original_transaction_type: originalTransactionType,
        creation_title: creationTitle,
        submitter_creation_n: record.substr(109, 20).trim().length
          ? record.substr(109, 20).trim()
          : null,
        recipient_creation_n: record.substr(129, 20).trim().length
          ? record.substr(129, 20).trim()
          : null,
        processing_date: processingDate,
        transaction_status: transactionStatus,
      },
    ]);

    this.transactionIndex += 1;
    this.totalTransactions += 1;
    this.recordsInGroup += 1;
    this.lastChain = 0;

    if (rejectTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }
    if (rejectGroup) {
      this.groupsToReject.push(this.groupIndex);
    }
  }

  processXPURecord(record: string) {
    let rejectTransaction = false;
    const recordType = record.substr(0, 3);
    const ipn = record.substr(21, 9).trim().length ? record.substr(21, 9).trim() : null;
    const publisherName = record.substr(30, 45).trim().length ? record.substr(30, 45).trim() : null;
    const publisherSeqN = parseInt(record.substr(19, 2), 10);
    const ipiNameN = record.substr(87, 11).trim().length
      ? parseInt(record.substr(87, 11), 10)
      : null;
    const prOs = parseInt(record.substr(115, 5), 10) / 100;
    const mrOs = parseInt(record.substr(123, 5), 10) / 100;
    const srOs = parseInt(record.substr(131, 5), 10) / 100;
    const prSoc = record.substr(112, 3).trim().length ? parseInt(record.substr(112, 3), 10) : null;
    const mrSoc = record.substr(120, 3).trim().length ? parseInt(record.substr(120, 3), 10) : null;
    const srSoc = record.substr(128, 3).trim().length ? parseInt(record.substr(128, 3), 10) : null;
    let type = record.substr(76, 2).trim().length ? record.substr(76, 2).trim() : null;
    let publisherUnknown = record.substr(75, 1).trim().length ? record.substr(75, 1) : null;
    let specialAgreement = record.substr(136, 1).trim().length ? record.substr(136, 1) : null;
    let firstRecordingRefusal = record.substr(137, 1).trim().length ? record.substr(137, 1) : null;
    let taxId = record.substr(78, 9).trim().length ? parseInt(record.substr(78, 9), 10) : null;
    let agreementType = record.substr(180, 2).trim().length ? record.substr(180, 2) : null;
    let usaLicense = record.substr(182, 1).trim().length ? record.substr(182, 1) : null;

    if (typeof prSoc === 'number' && !Number.isNaN(prSoc)) {
      this.addSociety(prSoc);
    }
    if (typeof mrSoc === 'number' && !Number.isNaN(mrSoc)) {
      this.addSociety(mrSoc);
    }
    if (typeof srSoc === 'number' && !Number.isNaN(srSoc)) {
      this.addSociety(srSoc);
    }
    if (publisherName && ipn) {
      if (!(ipn in this.rightHolders)) {
        this.addRightHolder(publisherName, ipiNameN, ipn);
      }
    } else if (publisherName) {
      if (!(publisherName in this.rightHolders)) {
        this.addRightHolder(publisherName, ipiNameN, publisherName);
      }
    }

    if (this.lastChain !== publisherSeqN) {
      this.lastChain += 1;
      this.chainType = recordType;
      if (!CwrParserService.valueInArray(type, ['E', 'PA'])) {
        this.errors.push({
          message: 'The first SPU record within a chain must be for type "E" or "PA"',
          type: 'TR',
          line: this.index,
          lineContent: record,
          transactionInfo: this.transactionInfo,
        });
        rejectTransaction = true;
      }
    } else if (type === 'E') {
      this.errors.push({
        message: 'There can only be one original publisher in a publisher chain',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      CwrParserService.valueInArray(type, ['SE', 'AM', 'PA', 'ES']) &&
      (prOs > 0 || mrOs > 0 || srOs > 0)
    ) {
      this.errors.push({
        message:
          'If publisher type is equal to "SE" or "AM" or "PA" or "ES", ownership shares must be 0',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (this.chainType !== 'OPU' && recordType === 'OPU') {
      this.errors.push({
        message:
          'An OPU can not appear in a chain started with a controlled original publisher SPU',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (this.chainType !== 'SPU' && recordType === 'SPU') {
      this.errors.push({
        message: 'Only OPU records may appear in a chain begun with an OPU',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (type === 'AQ' && prOs === 0 && mrOs === 0 && srOs === 0) {
      this.errors.push({
        message: 'If type is Acquirer, at least one share must be greater than 0',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (recordType === 'SPU' && ipn === null) {
      this.errors.push({
        message: 'If Record Type is equal to SPU, Interested Party # must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if ((recordType === 'SPU' || publisherUnknown !== 'Y') && publisherName === null) {
      this.errors.push({
        message:
          'If Record Type is SPU or unknown indicator is not "Y", publisher name must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (recordType === 'SPU' && type === null) {
      this.errors.push({
        message: 'If Record Type is equal to SPU, Publisher Type must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      type !== null &&
      !CwrParserService.valueInArray(type, ['AQ', 'AM', 'PA', 'E', 'ES', 'SE'])
    ) {
      this.errors.push({
        message: 'If publisher type is entered, it must match an entry in Publisher Type Table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (recordType === 'SPU' && publisherUnknown !== null) {
      this.errors.push({
        message: 'If Record Type is equal to SPU, Publisher Unknown Indicator must be blank',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      recordType === 'OPU' &&
      !CwrParserService.valueInArray(publisherUnknown, [null, 'Y', 'N'])
    ) {
      this.errors.push({
        message:
          'If Record Type is OPU and publisher Unknown Indicator is entered, it must be "Y" or "N"',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: publisherUnknown,
        newValue: 'N',
      });
      publisherUnknown = 'N';
    }
    if (recordType === 'OPU' && publisherUnknown === 'Y' && publisherName !== null) {
      this.errors.push({
        message:
          'If Record Type is OPU and publisher Unknown Indicator is "Y", Publisher Name must be blank',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: publisherUnknown,
        newValue: 'N',
      });
      publisherUnknown = 'N';
    }
    if (
      prSoc < 0 ||
      prSoc > 310 ||
      mrSoc < 0 ||
      mrSoc > 310 ||
      srSoc < 0 ||
      srSoc > 310 ||
      Number.isNaN(prSoc) ||
      Number.isNaN(mrSoc) ||
      Number.isNaN(srSoc)
    ) {
      this.errors.push({
        message: 'If entered, affiliation society # must match an entry in its table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      prOs < 0 ||
      prOs > 5000 ||
      mrOs < 0 ||
      mrOs > 10000 ||
      srOs < 0 ||
      srOs > 10000 ||
      Number.isNaN(prOs) ||
      Number.isNaN(mrOs) ||
      Number.isNaN(srOs)
    ) {
      this.errors.push({
        message: 'Ownership shares must be numeric between 0 and 10000 (5000 for performance)',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      specialAgreement !== null &&
      !CwrParserService.valueInArray(specialAgreement, ['R', 'L', 'B', 'Y', 'N', 'U'])
    ) {
      this.errors.push({
        message: 'If entered, Special Agreement Indicator must match an entry in its table',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: specialAgreement,
        newValue: '',
      });
      specialAgreement = null;
    }
    if (
      firstRecordingRefusal !== null &&
      !CwrParserService.valueInArray(firstRecordingRefusal, ['Y', 'N'])
    ) {
      this.errors.push({
        message: 'If entered, First Recording Refusal Ind must be equal to Y or N',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: firstRecordingRefusal,
        newValue: '',
      });
      firstRecordingRefusal = null;
    }
    if (taxId !== null && Number.isNaN(taxId)) {
      this.errors.push({
        message: 'If entered, Tax ID must be numeric',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: record.substr(78, 9),
        newValue: '0',
      });
      taxId = 0;
    }
    if (recordType === 'OPU' && !CwrParserService.valueInArray(specialAgreement, ['L', null])) {
      this.errors.push({
        message: 'If Record Type is "OPU", Special Agreements Indicator can only be "L" or blank',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: specialAgreement,
        newValue: '',
      });
      specialAgreement = null;
    }
    if (
      recordType === 'OPU' &&
      !CwrParserService.valueInArray(type, ['AQ', 'AM', 'PA', 'E', 'ES', 'SE'])
    ) {
      this.errors.push({
        message:
          'If Record Type is "OPU", and Publisher type is invalid or missing, default to "E"',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: type,
        newValue: 'E',
      });
      type = 'E';
    }
    if (
      agreementType !== null &&
      !CwrParserService.valueInArray(agreementType, ['OS', 'PS', 'PG', 'OG'])
    ) {
      this.errors.push({
        message: 'If Agreement Type is entered, it must match an entry in the Agreement Type table',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: agreementType,
        newValue: '',
      });
      agreementType = null;
    }
    if (usaLicense !== null && !CwrParserService.valueInArray(usaLicense, ['A', 'B', 'S'])) {
      this.errors.push({
        message:
          'If USA License Ind is entered, it must match a value in the USA License Indicator table',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: usaLicense,
        newValue: '',
      });
      usaLicense = null;
    }
    if (type === 'AQ' && (this.lastRecord !== 'SPU' || this.lastPubType !== 'E')) {
      this.errors.push({
        message:
          'If the role code is "AQ", this SPU record must follow an SPU record with a role code of "E"',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }

    this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
      record_type: recordType,
      transaction_sequence_n: parseInt(record.substr(3, 8), 10),
      record_sequence_n: parseInt(record.substr(11, 8), 10),
      publisher_sequence_n: publisherSeqN,
      publisher: {
        ip_n: ipn,
        publisher_name: publisherName,
        tax_id: taxId,
        ipi_name_n: ipiNameN,
        ipi_base_n: record.substr(139, 13).trim().length ? record.substr(139, 13) : null,
      },
      publisher_unknown: publisherUnknown,
      publisher_type: type,
      submitter_agreement_n: record.substr(98, 14).trim().length
        ? record.substr(98, 14).trim()
        : null,
      pr_society: prSoc,
      pr_ownership_share: prOs,
      mr_society: mrSoc,
      mr_ownership_share: mrOs,
      sr_society: srSoc,
      sr_ownership_share: srOs,
      special_agreements: specialAgreement,
      first_recording_refusal: firstRecordingRefusal,
      international_standard_code: record.substr(152, 14).trim().length
        ? record.substr(152, 14).trim()
        : null,
      society_assigned_agreement_n: record.substr(166, 14).trim().length
        ? record.substr(166, 14).trim()
        : null,
      agreement_type: agreementType,
      usa_license: usaLicense,
    });

    if (rejectTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }

    if ((type === 'E' || type === 'PA') && recordType === 'SPU') {
      this.publishersIPn[ipn] = publisherName;
    }
    if (recordType === 'SPU') {
      this.currentSeqN = 1;
      this.lastPubIpn = ipn;
    }
    this.lastWriterIpn = ipn;
    this.recordsInGroup += 1;
    this.lastPubType = type;
  }

  processSPTRecord(record: string) {
    let rejectRecord = false;
    let rejectTransaction = false;
    const prCs = parseInt(record.substr(34, 5), 10) / 100;
    const mrCs = parseInt(record.substr(39, 5), 10) / 100;
    const srCs = parseInt(record.substr(44, 5), 10) / 100;
    const ieIndicator = record.substr(49, 1);
    const ipn = record.substr(19, 9).trim();
    let sharesChange = record.substr(54, 1).trim().length ? record.substr(54, 1) === 'Y' : null;
    const sequenceN = parseInt(record.substr(55, 3), 10);
    const tisNumericCode = parseInt(record.substr(50, 4), 10);

    if (ieIndicator === 'I' && prCs === 0 && mrCs === 0 && srCs === 0) {
      this.errors.push({
        message: 'If the inclusion exclusion indicator is "I", one share must be greater than 0',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (this.lastRecord !== 'SPU' && this.lastRecord !== 'SPT') {
      this.errors.push({
        message: 'When entered SPT records must follow SPU or SPT',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (this.lastPubIpn !== ipn) {
      this.errors.push({
        message:
          'The IP# must be entered and must be equal to the interested party # on previous SPU',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      prCs < 0 ||
      prCs > 5000 ||
      mrCs < 0 ||
      mrCs > 10000 ||
      srCs < 0 ||
      srCs > 10000 ||
      Number.isNaN(prCs) ||
      Number.isNaN(mrCs) ||
      Number.isNaN(srCs)
    ) {
      this.errors.push({
        message: 'shares must be between 0 and 10000 (performance maximum 5000)',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (!CwrParserService.tisInArrayObjects(tisNumericCode, this.territories)) {
      this.errors.push({
        message: 'TIS Numeric Code must be entered and must match an entry in the TIS database',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (!CwrParserService.valueInArray(ieIndicator, ['I', 'E'])) {
      this.errors.push({
        message: 'Inclusion/Exclusion Indicator must be entered and must be either "E" or "I"',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (sharesChange !== null && !CwrParserService.valueInArray(sharesChange, [true, false])) {
      this.errors.push({
        message: 'If shares change is entered, it must be set to "Y" or "N"',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'SPT', description: recordDescriptions.SPT },
        originalValue: record.substr(54, 1).trim(),
        newValue: 'N',
      });
      sharesChange = false;
    }
    if (Number.isNaN(sequenceN)) {
      this.errors.push({
        message: 'Sequence # must be present',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'SPT', description: recordDescriptions.SPT },
      });
      rejectRecord = true;
    }
    if (sequenceN !== this.currentSeqN) {
      this.errors.push({
        message:
          'Sequence # must be 1 for the first SW after an SWR and increment by 1 for each subsequent SWT',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'SPT', description: recordDescriptions.SPT },
      });
      rejectRecord = true;
    }
    if (this.chainType === 'OPU') {
      this.errors.push({
        message: 'Territory records are not valid in a chain beginning with an OPU',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'SPT', description: recordDescriptions.SPT },
      });
      rejectRecord = true;
    }

    if (!rejectRecord) {
      this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
        record_type: record.substr(0, 3),
        transaction_sequence_n: parseInt(record.substr(3, 8), 10),
        record_sequence_n: parseInt(record.substr(11, 8), 10),
        ip_n: ipn,
        pr_collection_share: prCs,
        mr_collection_share: mrCs,
        sr_collection_share: srCs,
        inclusion_exclusion_indicator: ieIndicator,
        tis_numeric_code: tisNumericCode,
        shares_change: sharesChange,
        sequence_n: sequenceN,
      });
    }

    if (rejectTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }

    this.recordsInGroup += 1;
    this.currentSeqN += 1;
  }

  processXWRRecord(record: string) {
    let rejectTransaction = false;
    const writerIPn = record.substr(19, 9).trim().length ? record.substr(19, 9).trim() : null;
    const recordType = record.substr(0, 3);
    let writerUnknown = record.substr(103, 1).trim().length ? record.substr(103, 1) : null;
    const writerLastName = record.substr(28, 45).trim().length
      ? record.substr(28, 45).trim()
      : null;
    const writerFirstName = record.substr(73, 30).trim().length
      ? record.substr(73, 30).trim()
      : null;
    const writerDesignation = record.substr(104, 2).trim().length
      ? record.substr(104, 2).trim()
      : null;
    const ipiNameN = record.substr(115, 11).trim().length
      ? parseInt(record.substr(115, 11), 10)
      : null;
    const prOs = record.substr(129, 5).trim().length
      ? parseInt(record.substr(129, 5), 10) / 100
      : null;
    const mrOs = record.substr(137, 5).trim().length
      ? parseInt(record.substr(137, 5), 10) / 100
      : null;
    const srOs = record.substr(145, 5).trim().length
      ? parseInt(record.substr(145, 5), 10) / 100
      : null;
    const prSoc = record.substr(126, 3).trim().length ? parseInt(record.substr(126, 3), 10) : null;
    const mrSoc = record.substr(134, 3).trim().length ? parseInt(record.substr(134, 3), 10) : null;
    const srSoc = record.substr(142, 3).trim().length ? parseInt(record.substr(142, 3), 10) : null;
    let reversionary = record.substr(150, 1).trim().length ? record.substr(150, 1) : null;
    let firstRecordingRefusal = record.substr(151, 1).trim().length ? record.substr(151, 1) : null;
    let workForHire = record.substr(152, 1).trim().length ? record.substr(152, 1) : null;
    let taxId = record.substr(106, 9).trim().length ? parseInt(record.substr(106, 9), 10) : null;
    let usaLicense = record.substr(179, 1).trim().length ? record.substr(179, 1) : null;
    const submitterAgreementNumber = record.substr(98, 14).trim().length
      ? record.substr(98, 14).trim()
      : null;
    const societyAssignedAgreementNumber = record.substr(166, 14).trim().length
      ? record.substr(166, 14).trim()
      : null;

    if (typeof prSoc === 'number' && !Number.isNaN(prSoc)) {
      this.addSociety(prSoc);
    }
    if (typeof mrSoc === 'number' && !Number.isNaN(mrSoc)) {
      this.addSociety(mrSoc);
    }
    if (typeof srSoc === 'number' && !Number.isNaN(srSoc)) {
      this.addSociety(srSoc);
    }

    let name = writerLastName;
    if (writerFirstName) {
      name = `${writerFirstName} ${writerLastName}`;
    }
    if (name && writerIPn) {
      if (!(writerIPn in this.rightHolders)) {
        this.addRightHolder(name, ipiNameN, writerIPn);
      }
    } else if (name) {
      if (!(name in this.rightHolders)) {
        this.addRightHolder(name, ipiNameN, name);
      }
    }

    if (recordType === 'SWR' && writerIPn === null) {
      this.errors.push({
        message: 'If record type is equal to SWR, IP # must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if ((recordType === 'SWR' || writerUnknown !== 'Y') && writerLastName === null) {
      this.errors.push({
        message:
          'If record type is SWR or writer unknown is not equal to "Y", writer last name must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (recordType === 'SWR' && writerUnknown !== null) {
      this.errors.push({
        message: 'If record type is equal to SWR, writer unknown must be blank',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      recordType === 'OWR' &&
      writerUnknown !== null &&
      !CwrParserService.valueInArray(writerUnknown, ['Y', 'N'])
    ) {
      this.errors.push({
        message:
          'If record type is equal to OWR and writer unknown is entered, it must be "Y" or "N"',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: writerUnknown,
        newValue: 'N',
      });
      writerUnknown = 'N';
    }
    if (recordType === 'OWR' && writerUnknown === 'Y' && writerLastName !== null) {
      this.errors.push({
        message:
          'If record type is equal to OWR and writer unknown is "Y", writer last name must be blank',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: writerUnknown,
        newValue: 'N',
      });
      writerUnknown = 'N';
    }
    if (recordType === 'SWR' && writerDesignation === null) {
      this.errors.push({
        message: 'For SWR records, writer designation code must be entered',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      writerDesignation !== null &&
      !CwrParserService.valueInArray(writerDesignation, [
        'AD',
        'AR',
        'A',
        'C',
        'CA',
        'SR',
        'SA',
        'TR',
        'PA',
      ])
    ) {
      this.errors.push({
        message: 'ntered, Writer Designation Code must match an entry in its table',
        type: 'If',
        line: this.index,
        lineContent: record,
      });
    }
    if (
      prSoc < 0 ||
      prSoc > 310 ||
      mrSoc < 0 ||
      mrSoc > 310 ||
      srSoc < 0 ||
      srSoc > 310 ||
      Number.isNaN(prSoc) ||
      Number.isNaN(mrSoc) ||
      Number.isNaN(srSoc)
    ) {
      this.errors.push({
        message: 'If entered, affiliation society # must match an entry in its table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (
      prOs < 0 ||
      prOs > 10000 ||
      mrOs < 0 ||
      mrOs > 10000 ||
      srOs < 0 ||
      srOs > 10000 ||
      Number.isNaN(prOs) ||
      Number.isNaN(mrOs) ||
      Number.isNaN(srOs)
    ) {
      this.errors.push({
        message: 'Ownership share must be numeric between 0 and 10000',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (reversionary !== null && !CwrParserService.valueInArray(reversionary, ['Y', 'N', 'U'])) {
      this.errors.push({
        message: 'if entered, reversionary indicator must be equal to Y, N or U',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: reversionary,
        newValue: '',
      });
      reversionary = null;
    }
    if (
      firstRecordingRefusal !== null &&
      !CwrParserService.valueInArray(firstRecordingRefusal, ['Y', 'N'])
    ) {
      this.errors.push({
        message: 'if entered, first recording refusal indicator must be equal to Y or N',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: firstRecordingRefusal,
        newValue: '',
      });
      firstRecordingRefusal = null;
    }
    if (workForHire !== null && !CwrParserService.valueInArray(workForHire, ['Y', 'N'])) {
      this.errors.push({
        message: 'if entered, work for hire indicator must be equal to Y or N',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: workForHire,
        newValue: '',
      });
      workForHire = null;
    }
    if (Number.isNaN(taxId)) {
      this.errors.push({
        message: 'if entered, tax id must be numeric',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: record.substr(106, 9).trim(),
        newValue: '',
      });
      taxId = null;
    }
    if (usaLicense !== null && !CwrParserService.valueInArray(usaLicense, ['A', 'B', 'S'])) {
      this.errors.push({
        message: 'If USA License Ind is entered, it must match a value in its table',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: recordType, description: recordDescriptions[recordType] },
        originalValue: usaLicense,
        newValue: '',
      });
      usaLicense = null;
    }

    this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
      record_type: recordType,
      transaction_sequence_n: parseInt(record.substr(3, 8), 10),
      record_sequence_n: parseInt(record.substr(11, 8), 10),
      writer: {
        ip_n: writerIPn,
        writer_last_name: writerLastName,
        writer_first_name: writerFirstName,
        tax_id: taxId,
        ipi_name_n: ipiNameN,
        ipi_base_n: record.substr(154, 13).trim().length ? record.substr(154, 13).trim() : null,
        personal_number: record.substr(167, 12).trim().length
          ? parseInt(record.substr(167, 12), 10)
          : null,
      },
      writer_unknown: writerUnknown,
      writer_designation: writerDesignation,
      pr_society: prSoc,
      pr_ownership_share: prOs,
      mr_society: mrSoc,
      mr_ownership_share: mrOs,
      sr_society: srSoc,
      sr_ownership_share: srOs,
      submitter_agreement_number: submitterAgreementNumber,
      society_assigned_agreement_number: societyAssignedAgreementNumber,
      reversionary,
      first_recording_refusal: firstRecordingRefusal,
      work_for_hire: workForHire,
      usa_license: usaLicense,
    });

    if (rejectTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }

    if (recordType === 'SWR') {
      this.currentSeqN = 1;
    }
    if (CwrParserService.valueInArray(writerDesignation, ['AR', 'AD', 'SR', 'SA', 'TR'])) {
      this.foundMODType = true;
    }
    if (CwrParserService.valueInArray(writerDesignation, ['A', 'CA', 'C'])) {
      this.foundCAACType = true;
    }
    this.lastWriterIpn = writerIPn;
    this.recordsInGroup += 1;
  }

  processSWTRecord(record: string) {
    let rejectRecord = false;
    let rejectTransaction = false;
    let prCs = parseInt(record.substr(28, 5), 10) / 100;
    let mrCs = parseInt(record.substr(33, 5), 10) / 100;
    let srCs = parseInt(record.substr(38, 5), 10) / 100;
    const ipn = record.substr(19, 9).trim();
    const tisNumericCode = parseInt(record.substr(44, 4), 10);
    const iEIndicator = record.substr(43, 1);
    let sharesChange = record.substr(48, 1).trim().length ? record.substr(48, 1) === 'Y' : null;
    const sequenceN = parseInt(record.substr(49, 3), 10);

    if (this.lastRecord !== 'SWR' && this.lastRecord !== 'SWT') {
      this.errors.push({
        message: 'SWT record must follow a SWR or SWT record',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (!CwrParserService.valueInArray(iEIndicator, ['I', 'E'])) {
      this.errors.push({
        message: 'Inclusion/Exclusion Indicator must be entered and must be either "E" or "I"',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (iEIndicator === 'I' && prCs === 0 && mrCs === 0 && srCs === 0) {
      this.errors.push({
        message:
          'If inclusion exclusion indicator is "I" at least one share must be greater than 0',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (iEIndicator === 'E' && (prCs > 0 || mrCs > 0 || srCs > 0)) {
      this.errors.push({
        message: 'If the inclusion exclusion indicator is "E", collection shares must be set to 0',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'SWT', description: recordDescriptions.SWT },
        originalValue: `perfShare: ${prCs}, mechShare: ${mrCs}, synchShare: ${srCs}`,
        newValue: 'perfShare: 0, mechShare: 0, synchShare: 0',
      });
      prCs = 0;
      mrCs = 0;
      srCs = 0;
    }
    if (
      prCs < 0 ||
      prCs > 10000 ||
      mrCs < 0 ||
      mrCs > 10000 ||
      srCs < 0 ||
      srCs > 10000 ||
      Number.isNaN(prCs) ||
      Number.isNaN(mrCs) ||
      Number.isNaN(srCs)
    ) {
      this.errors.push({
        message: 'collection shares must be between 0 and 100%',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (!CwrParserService.tisInArrayObjects(tisNumericCode, this.territories)) {
      this.errors.push({
        message: 'TIS Numeric Code must be entered and must match an entry in the TIS database',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (ipn !== this.lastWriterIpn) {
      this.errors.push({
        message:
          'The IP # must be entered and must be equal to the IP # on the previous SWR record',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (sharesChange !== null && !CwrParserService.valueInArray(sharesChange, [true, false])) {
      this.errors.push({
        message: 'If shares change is entered, it must be set to "Y" or "N"',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'SWT', description: recordDescriptions.SWT },
        originalValue: record.substr(48, 1).trim(),
        newValue: 'N',
      });
      sharesChange = false;
    }
    if (Number.isNaN(sequenceN)) {
      this.errors.push({
        message: 'Sequence # must be present',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'SWT', description: recordDescriptions.SWT },
      });
      rejectRecord = true;
    }
    if (sequenceN !== this.currentSeqN) {
      this.errors.push({
        message:
          'Sequence # must be 1 for the first SW after an SWR and increment by 1 for each subsequent SWT',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'SWT', description: recordDescriptions.SWT },
      });
      rejectRecord = true;
    }

    if (!rejectRecord) {
      this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
        record_type: record.substr(0, 3),
        transaction_sequence_n: parseInt(record.substr(3, 8), 10),
        record_sequence_n: parseInt(record.substr(11, 8), 10),
        ip_n: ipn,
        pr_collection_share: prCs,
        mr_collection_share: mrCs,
        sr_collection_share: srCs,
        inclusion_exclusion_indicator: iEIndicator,
        tis_numeric_code: tisNumericCode,
        shares_change: sharesChange,
        sequence_n: sequenceN,
      });
    }

    if (rejectTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }

    this.recordsInGroup += 1;
    this.currentSeqN += 1;
  }

  processPWRRecord(record: string) {
    let rejectRecord = false;
    let rejectTransaction = false;
    const pubIPn = record.substr(19, 9).trim();
    const writerIPn = record.substr(101, 9).trim();
    let publisherName = record.substr(28, 45).trim();

    if (this.lastRecord !== 'SWR' && this.lastRecord !== 'SWT' && this.lastRecord !== 'PWR') {
      this.errors.push({
        message: 'PWR must immediately follow an SWR or an SWT or another PWR record',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'PWR', description: recordDescriptions.PWR },
      });
    }
    if (!(pubIPn in this.publishersIPn)) {
      this.errors.push({
        message: 'Publisher IP # must match an Interested Party # on a preceding SPU record',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    } else if (this.publishersIPn[pubIPn] !== publisherName) {
      this.errors.push({
        message:
          'Publisher Name must match the name of the publisher referenced by the Interested Party# field',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'PWR', description: recordDescriptions.PWR },
        originalValue: publisherName,
        newValue: this.publishersIPn[pubIPn],
      });
      publisherName = this.publishersIPn[pubIPn];
    }
    if (this.lastWriterIpn !== writerIPn) {
      this.errors.push({
        message:
          'Writer IP # must be entered and it must match the IP #entered on the preceding SWR record',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'PWR', description: recordDescriptions.PWR },
      });
      rejectRecord = true;
    }

    if (!rejectRecord) {
      this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
        record_type: record.substr(0, 3),
        transaction_sequence_n: parseInt(record.substr(3, 8), 10),
        record_sequence_n: parseInt(record.substr(11, 8), 10),
        publisher_ip_n: pubIPn,
        publisher_name: publisherName,
        submitter_agreement_n: record.substr(73, 14).trim().length
          ? record.substr(73, 14).trim()
          : null,
        society_assigned_agreement_n: record.substr(87, 14).trim().length
          ? record.substr(87, 14).trim()
          : null,
        writer_ip_n: writerIPn,
      });
    }

    if (rejectTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }

    this.recordsInGroup += 1;
  }

  processALTRecord(record: string) {
    let rejectRecord = false;
    const alternateTitle = record.substr(19, 60).trim();
    let titleType = record.substr(79, 2);
    const languageCode = record.substr(81, 2).trim().length ? record.substr(81, 2) : null;

    if (alternateTitle === '') {
      this.errors.push({
        message: 'Alternate Title must be entered',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'ALT', description: recordDescriptions.ALT },
      });
      rejectRecord = true;
    }
    if ((titleType === 'OL' || titleType === 'AL') && languageCode === null) {
      this.errors.push({
        message: 'If the Title Type is equal to “OL” or “AL”, Language Code must be entered',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'ALT', description: recordDescriptions.ALT },
      });
      rejectRecord = true;
    }
    if (languageCode !== null && !CwrParserService.valueInArrayObjects(languageCode, LANGUAGES)) {
      this.errors.push({
        message: 'Language Code, if entered, must match an entry in the Language Code Table',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectRecord = true;
    }
    if (
      !CwrParserService.valueInArray(titleType, [
        'AT',
        'TE',
        'FT',
        'IT',
        'TT',
        'PT',
        'RT',
        'ET',
        'OL',
        'AL',
      ])
    ) {
      this.errors.push({
        message:
          'Title Type must be entered and must match an entry in the Title Type table other than "OT"',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'ALT', description: recordDescriptions.ALT },
        originalValue: titleType,
        newValue: 'AT',
      });
      titleType = 'AT';
    }

    if (!rejectRecord) {
      this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
        record_type: record.substr(0, 3),
        transaction_sequence_n: parseInt(record.substr(3, 8), 10),
        record_sequence_n: parseInt(record.substr(11, 8), 10),
        alternate_title: alternateTitle,
        title_type: titleType,
        language_code: languageCode,
      });
    }

    this.recordsInGroup += 1;
  }

  processPERRecord(record: string) {
    const paLastName = record.substr(19, 45).trim();
    const paFirstName = record.substr(64, 30).trim().length ? record.substr(64, 30).trim() : null;
    const paIPINameN = record.substr(94, 11).trim().length
      ? parseInt(record.substr(94, 11), 10)
      : null;
    const paIPIBaseN = record.substr(105, 13).trim().length ? record.substr(105, 13).trim() : null;
    let rejectRecord = false;

    if (paLastName === '') {
      this.errors.push({
        message: 'Performing Artist Last Name must be entered',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'PER', description: recordDescriptions.PER },
      });
      rejectRecord = true;
    }

    if (!rejectRecord) {
      this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
        record_type: record.substr(0, 3),
        transaction_sequence_n: parseInt(record.substr(3, 8), 10),
        record_sequence_n: parseInt(record.substr(11, 8), 10),
        performing_artist_last_name: paLastName,
        performing_artist_first_name: paFirstName,
        performing_artist_ipi_name_n: paIPINameN,
        performing_artist_ipi_base_n: paIPIBaseN,
      });
    }

    this.recordsInGroup += 1;
    this.addPerformer(paLastName, paFirstName, paIPINameN, paIPIBaseN);
  }

  processRECRecord(record: string) {
    let rejectRecord = false;

    if (record.length < 20) {
      this.errors.push({
        message: 'At least one of the optional fields must be entered',
        type: 'RR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'REC', description: recordDescriptions.REC },
      });
      rejectRecord = true;
    }

    let firstReleaseDate = record.substr(19, 8).trim().length
      ? `${record.substr(19, 4)}-${record.substr(23, 2)}-${record.substr(25, 2)}`
      : null;
    let firstReleaseDuration = record.substr(87, 6).trim().length
      ? `${record.substr(87, 2)}:${record.substr(89, 2)}:${record.substr(91, 2)}`
      : null;
    let recordingFormat = record.substr(261, 1).trim().length ? record.substr(261, 1) : null;
    let recordingTechnique = record.substr(262, 1).trim().length ? record.substr(262, 1) : null;
    let mediaType = record.substr(263, 2).trim().length ? record.substr(263, 2) : null;

    if (firstReleaseDate !== null) {
      if (!this.dateRegex.test(firstReleaseDate) && firstReleaseDate !== '0000-00-00') {
        this.errors.push({
          message: 'If entered, First Release Date must be a valid date',
          type: 'FR',
          line: this.index,
          lineContent: record,
          transactionInfo: this.transactionInfo,
          recordType: { label: 'REC', description: recordDescriptions.REC },
          originalValue: firstReleaseDate,
          newValue: '0000-00-00',
        });
        firstReleaseDate = '0000-00-00';
      }
    }
    if (firstReleaseDuration !== null) {
      if (!this.durationRegex.test(firstReleaseDuration) && firstReleaseDuration !== '00:00:00') {
        this.errors.push({
          message:
            'If entered, First Release Duration must be a combination of hours, minutes and seconds',
          type: 'FR',
          line: this.index,
          lineContent: record,
          transactionInfo: this.transactionInfo,
          recordType: { label: 'REC', description: recordDescriptions.REC },
          originalValue: firstReleaseDuration,
          newValue: '00:00:00',
        });
        firstReleaseDuration = '00:00:00';
      }
    }
    if (recordingFormat !== null) {
      if (recordingFormat !== 'A' && recordingFormat !== 'V') {
        this.errors.push({
          message: 'If entered, Recording Format must be "A" for Audio or "V" for video',
          type: 'FR',
          line: this.index,
          lineContent: record,
          transactionInfo: this.transactionInfo,
          recordType: { label: 'REC', description: recordDescriptions.REC },
          originalValue: recordingFormat,
          newValue: 'A',
        });
        recordingFormat = 'A';
      }
    }
    if (recordingTechnique !== null) {
      if (recordingTechnique !== 'A' && recordingTechnique !== 'D' && recordingTechnique !== 'U') {
        this.errors.push({
          message:
            'If entered, Recording Technique must be "A" for analogue, "D" for digital, "U" for unknown',
          type: 'FR',
          line: this.index,
          lineContent: record,
          transactionInfo: this.transactionInfo,
          recordType: { label: 'REC', description: recordDescriptions.REC },
          originalValue: recordingTechnique,
          newValue: 'U',
        });
        recordingTechnique = 'U';
      }
    }
    if (
      mediaType !== null &&
      !CwrParserService.valueInArray(mediaType, [
        'S',
        'EP',
        'DS',
        'RDS',
        'RMS',
        'EPM',
        'MLP',
        'LP',
        'LP2',
        'LP3',
        'LP4',
        'SCD',
        'CDS',
        'CDM',
        'RCD',
        'CD',
        'CD2',
        'SA',
        'SA2',
        'CES',
        'CXS',
        'CXM',
        'RCE',
        'CEP',
        'CE',
        'CE2',
        'SMC',
        'SM2',
        'MMC',
        'EMC',
        'RMC',
        'MCP',
        'MC',
        'MC2',
        'MC3',
        'MC4',
        'DMC',
        'MDS',
        'MDR',
        'MDP',
        'MD',
        'MD2',
        'DC',
        'DC2',
        'DV1',
        'DV2',
        'DV3',
        'DV4',
        'DW',
        'DM',
        'DL',
      ])
    ) {
      this.errors.push({
        message:
          'If entered, the Media type must match an entry from the BIEM/CISAC list of Media Types',
        type: 'FR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
        recordType: { label: 'REC', description: recordDescriptions.REC },
        originalValue: mediaType,
        newValue: null,
      });
      mediaType = null;
    }

    if (!rejectRecord) {
      this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
        record_type: record.substr(0, 3),
        transaction_sequence_n: parseInt(record.substr(3, 8), 10),
        record_sequence_n: parseInt(record.substr(11, 8), 10),
        first_release_date: firstReleaseDate,
        first_release_duration: firstReleaseDuration,
        first_album_title: record.substr(98, 60).trim().length
          ? record.substr(98, 60).trim()
          : null,
        first_album_label: record.substr(158, 60).trim().length
          ? record.substr(158, 60).trim()
          : null,
        first_release_catalog_n: record.substr(218, 18).trim().length
          ? record.substr(218, 18).trim()
          : null,
        ean: record.substr(236, 13).trim().length ? record.substr(236, 13).trim() : null,
        isrc: record.substr(249, 12).trim().length ? record.substr(249, 12).trim() : null,
        recording_format: recordingFormat,
        recording_technique: recordingTechnique,
        media_type: mediaType,
      });
    }

    this.recordsInGroup += 1;
  }

  processMSGRecord(record: string) {
    let rejectTransaction = false;
    const messageType = record.substr(19, 1);
    const messageLevel = record.substr(31, 1);

    this.cwrJSON.transmission.groups[this.groupIndex].transactions[this.transactionIndex].push({
      record_type: record.substr(0, 3),
      transaction_sequence_n: parseInt(record.substr(3, 8), 10),
      record_sequence_n: parseInt(record.substr(11, 8), 10),
      message_type: messageType,
      original_record_sequence_n: parseInt(record.substr(20, 8), 10),
      original_record_type: record.substr(28, 3),
      message_level: messageLevel,
      validation_number: record.substr(32, 3),
      message_text: record.substr(35, 150).trim(),
    });

    if (!CwrParserService.valueInArray(messageType, ['F', 'R', 'T', 'G', 'E'])) {
      this.errors.push({
        message: 'message type must be entered and of a type in table list',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }
    if (!CwrParserService.valueInArray(messageLevel, ['F', 'R', 'T', 'G', 'E'])) {
      this.errors.push({
        message: 'message level must be entered and of a type in table list',
        type: 'TR',
        line: this.index,
        lineContent: record,
        transactionInfo: this.transactionInfo,
      });
      rejectTransaction = true;
    }

    if (rejectTransaction) {
      this.transactionsToReject.push({
        group: this.groupIndex,
        transaction: this.transactionIndex,
      });
    }

    this.recordsInGroup += 1;
  }

  addSociety(code: number) {
    if (!(code in this.societies)) {
      this.societies[code] = { code };
    }
  }

  addRightHolder(name: string, ipi: number, key: string) {
    let addRightHolder = true;

    if (typeof ipi === 'number' && !Number.isNaN(ipi)) {
      if (!(ipi in this.ipis)) {
        this.ipis[ipi] = ipi;
      } else {
        addRightHolder = false;
      }
    }

    if (addRightHolder) {
      if (!this.rightHolders[key]) {
        this.rightHolders[key] = new RightHolder();
        this.rightHolders[key].name = name;
      }

      if (typeof ipi === 'number' && !Number.isNaN(ipi)) {
        if (this.rightHolders[key].ipi && this.rightHolders[key].ipi !== ipi) {
          this.rightHolders[`${key}${ipi}`] = new RightHolder();
          this.rightHolders[`${key}${ipi}`].name = name;
        } else {
          this.rightHolders[key].ipi = ipi;
        }
        this.rightHolders[key].ipi = ipi;
      }
    } else {
      console.log({ name, ipi });
    }
  }

  addPerformer(lastName: string, firstName: string, ipiNameN: number, ipiBaseN: string) {
    let key = lastName;

    if (ipiNameN && this.performersIpis[ipiNameN]) {
      key = this.performersIpis[ipiNameN];
      if (ipiBaseN) {
        this.performersBases[ipiBaseN] = key;
      }
    }
    if (ipiBaseN && this.performersBases[ipiBaseN]) {
      key = this.performersBases[ipiBaseN];
    }

    if (!this.performers[key]) {
      this.performers[key] = {
        lastName,
        firstName,
        ipiNameN,
        ipiBaseN,
      };
    } else if (
      firstName !== this.performers[key].firstName ||
      ipiNameN !== this.performers[key].ipiNameN ||
      ipiBaseN !== this.performers[key].ipiBaseN
    ) {
      // If any field is different
      if (
        (ipiNameN && ipiNameN === this.performers[key].ipiNameN) ||
        (ipiBaseN && ipiBaseN === this.performers[key].ipiBaseN)
      ) {
        // If one of the unique fields is the same
        this.performers[key].firstName = firstName || this.performers[key].firstName;
        this.performers[key].ipiNameN = ipiNameN || this.performers[key].ipiNameN;
        this.performers[key].ipiBaseN = ipiBaseN || this.performers[key].ipiBaseN;
        this.performersIpis[ipiNameN] = key;
        this.performersBases[ipiBaseN] = key;
      } else if (ipiBaseN || ipiNameN) {
        // Different performer
        key = `${lastName}${ipiBaseN}${ipiNameN}`;
        this.performers[key] = {
          lastName,
          firstName,
          ipiNameN,
          ipiBaseN,
        };
        this.performersIpis[ipiNameN] = key;
        this.performersBases[ipiBaseN] = key;
      } else {
        this.performers[key].firstName = firstName || this.performers[key].firstName;
      }
    }
  }
}
