import { Serializable } from 'app/core/interfaces';

import { Territory } from './territory';
import { CurrencyDTO } from './currency';

export enum FinancialDataType {
  COMPANY = 'company',
  INDIVIDUAL = 'individual',
}
export interface BusinessInformation {
  name: string;
  CIF: string;
  address: string;
  postalCode: string;
  city: string;
  countryId: number;
}
export interface RepresentativeInformation {
  name?: string;
  surname?: string;
  birthdate: string;
  nif?: string;
  address: string;
  postalCode: string;
  city: string;
  countryId: number;
  country?: string;
}
export interface BankInfo {
  countryId: number;
  currencyId: string;
  bankAccount: string;
  country?: Territory;
  currency?: CurrencyDTO;
}
export interface FinancialDataDTO {
  type: FinancialDataType;
  businessInformation?: BusinessInformation;
  representativeInformation: RepresentativeInformation;
  bankInfo: BankInfo;
}

export class FinancialData implements Serializable<FinancialData> {
  type: FinancialDataType;
  businessInformation?: BusinessInformation;
  representativeInformation: RepresentativeInformation;
  bankInfo: BankInfo;

  constructor() {
    this.businessInformation = {
      name: '',
      CIF: '',
      address: '',
      postalCode: '',
      city: '',
      countryId: null,
    };
    this.bankInfo = {
      countryId: null,
      currencyId: '',
      bankAccount: '',
    };
    this.representativeInformation = {
      name: '',
      surname: '',
      birthdate: '',
      nif: '',
      address: '',
      postalCode: '',
      city: '',
      countryId: null,
      country: '',
    };
  }

  deserialize(input: FinancialDataDTO): FinancialData {
    if (!input) {
      return this;
    }

    this.type = input.type || null;
    this.businessInformation = input.businessInformation || null;
    this.representativeInformation = input.representativeInformation || null;
    this.bankInfo = input.bankInfo || null;

    return this;
  }
}
