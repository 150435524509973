export const DIGITAL_PLATFORMS_MOCK: Array<string> = [
  'Apple Music',
  'Beatport',
  'Deezer',
  'Spotify',
  'Others',
];

export enum DigitalPlatforms {
  APPLE_MUSIC = 'Apple Music',
  BEATPORT = 'Beatport',
  DEEZER = 'Deezer',
  SPOTIFY = 'Spotify',
  AMAZON = 'Amazon Music',
}
