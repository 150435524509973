import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Ticket } from 'app/shared/models/ticket';
import { TICKETS_MOCK_ME } from 'app/shared/mocks';
import {
  InfoResponseAPI,
  MessageAddedResponseAPII,
  MessageI,
  TicketFiltersI,
  TicketResponseAPII,
  TicketsResponse,
  TicketsResponseAPII,
} from 'app/shared/interfaces';
import { FormGroup } from '@angular/forms';

// interface TicketsResponse {
//   tickets: Array<TicketDTO>;
//   info: TicketsResponseInfoI;
// }

// interface TicketsResponseInfoI {
//   count: number;
//   offset: string;
//   limit: string;
// }

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getTickets(
    offset: number,
    limit: number,
    filtersToApply: TicketFiltersI,
  ): Promise<TicketsResponse> {
    let ticketsFetched: Array<Ticket> = null;
    let infoResponse: InfoResponseAPI;
    const ticketsResponse: TicketsResponse = {
      tickets: [],
      info: {
        count: 0,
        offset: '0',
        limit: '0',
      },
    };
    let filters = `?offset=${offset}&limit=${limit}`;
    if (filtersToApply.text) {
      filters += `&search=${filtersToApply.text}`;
    }
    if (filtersToApply.status) {
      filters += `&status=${filtersToApply.status}`;
    }

    if (environment.useMocks) {
      const allTickets = new Ticket()
        .deserializeArray(TICKETS_MOCK_ME.tickets)
        .filter((ticket: Ticket) => {
          if (!filtersToApply.text) {
            return true;
          }

          return (
            ticket.topic.includes(filtersToApply.text) ||
            ticket.unisonId.includes(filtersToApply.text) ||
            ticket.work.title.includes(filtersToApply.text)
          );
        })
        .filter((ticket: Ticket) =>
          filtersToApply.status ? ticket.status === filtersToApply.status : true,
        );

      ticketsFetched = allTickets.slice(offset, offset + limit);
      ticketsResponse.info = infoResponse;

      infoResponse = {
        count: allTickets.length,
        offset: offset.toString(),
        limit: limit.toString(),
      };
    } else {
      const url = `${this.apiUrl}/ticket/me/${filters}`;
      const response: TicketsResponseAPII = await this.http
        .get<TicketsResponseAPII>(url, httpOptions)
        .toPromise();

      const { tickets } = response;
      ticketsFetched = new Ticket().deserializeArray(tickets);
      ticketsResponse.info = response.info;
    }

    ticketsResponse.tickets = ticketsFetched;
    return ticketsResponse;
  }

  async getTicket(ticketId: string): Promise<Ticket> {
    let ticketFetched: Ticket = new Ticket();
    if (environment.useMocks) {
      const allTickets = new Ticket()
        .deserializeArray(TICKETS_MOCK_ME.tickets)
        .filter((ticket: Ticket) => {
          if (!ticketId) {
            return true;
          }

          return ticket.id.includes(ticketId);
        });
      [ticketFetched] = allTickets;
    } else {
      const url = `${this.apiUrl}/ticket/${ticketId}/`;
      const response: TicketResponseAPII = await this.http
        .get<TicketResponseAPII>(url, httpOptions)
        .toPromise();
      ticketFetched = new Ticket().deserialize(response.ticket);
    }
    return ticketFetched;
  }

  async createTicket(ticket: FormGroup): Promise<Ticket> {
    const url = `${this.apiUrl}/ticket/`;
    const dataToSend = {
      compositionId: ticket.get('ticketWork').value as string,
      description: ticket.get('ticketDescription').value as string,
      topic: ticket.get('ticketTopic').value as string,
      title: ticket.get('ticketTitle').value as string,
    };
    let ticketCreated: Ticket = new Ticket();
    if (environment.useMocks) {
      // ticketCreated = ticket;
      ticketCreated.id = 'test-ticket-created';
      ticketCreated.unisonId = 'ticket-created';
      ticketCreated.status = 'open';
      ticketCreated.updatedAt = new Date().toString();
      ticketCreated.createdAt = new Date().toString();
    } else {
      const response: TicketResponseAPII = await this.http
        .post<TicketResponseAPII>(url, dataToSend, httpOptions)
        .toPromise();
      ticketCreated = new Ticket().deserialize(response.ticket);
    }

    return ticketCreated;
  }

  async addMessageToTicket(ticketId: string, message: MessageI): Promise<void> {
    if (environment.useMocks) {
      // console.log(message);
    } else {
      try {
        const url = `${this.apiUrl}/ticket/${ticketId}/message/`;
        // const response: MessageAddedResponseAPII = await this.http.post<MessageAddedResponseAPII>(url, message, httpOptions).toPromise();
        await this.http.post<MessageAddedResponseAPII>(url, message, httpOptions).toPromise();
      } catch (error) {
        // console.log(error);
      }
    }
  }
}
