import { Serializable } from 'app/core/interfaces';
// eslint-disable-next-line import/no-cycle
import { UsageReport } from './usageReport';

export enum ReportType {
  LIVE_STREAMING = 'live_streaming',
  LIVE_LIVE = 'live_live',
  CUE_SHEET = 'cue_sheet',
}

export class ProductionReportDTO {
  id: string;
  type: ReportType;
  name: string;
  date: string;
  editorName?: string;
  artistName?: string;
  website?: string;
  eventURL?: string;
  duration?: number;
  startTime?: string;
  endTime?: string;
  promoter?: string;
  platform?: string;
  venue?: string;
  ticketPrice?: number;

  // cue-sheet
  productionType?: string;
  seriesName?: string;
  season?: string;
  episode: string;
  productionCompany?: string;

  // usageReports?: Array<UsageReport>; // TODO usageReport Class
  usageReports?: Array<{ compositionId: string }>; // TODO usageReport Class
}

export class ProductionReport implements Serializable<ProductionReport> {
  id: string;
  type: ReportType;
  name: string;
  date: string;
  editorName?: string;
  artistName?: string;
  website?: string;
  eventURL?: string;
  duration?: number;
  startTime?: string;
  endTime?: string;
  promoter?: string;
  platform?: string;
  venue?: string;
  ticketPrice?: number;

  // owners?: string;

  // cue-sheet
  productionType?: string;
  seriesName?: string;
  season?: string;
  episode: string;
  productionCompany?: string;

  usageReports?: Array<UsageReport>; // TODO usageReport Class
  // usageReports?: Array<{ compositionId: string }>; // TODO check

  deserialize(input: ProductionReportDTO): ProductionReport {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.type = input.type || null;
    this.name = input.name || '';
    this.date = input.date || '';
    this.editorName = input.editorName || '';
    this.artistName = input.artistName || '';
    this.website = input.website || '';
    this.eventURL = input.eventURL || '';
    this.duration = input.duration || 0;
    this.startTime = input.startTime || '';
    this.endTime = input.endTime || '';
    this.promoter = input.promoter || '';
    this.platform = input.platform || '';
    this.venue = input.venue || '';
    this.ticketPrice = input.ticketPrice || 0;

    this.productionType = input.productionType || '';
    this.seriesName = input.seriesName || '';
    this.season = input.season || '';
    this.episode = input.episode || '';
    this.productionCompany = input.productionCompany || '';

    this.usageReports = input.usageReports
      ? new UsageReport().deserializeArray(input.usageReports)
      : [];
    // this.usageReports = input.usageReports || [];

    return this;
  }

  deserializeArray(inputArray: Array<ProductionReportDTO>): Array<ProductionReport> {
    const workUsages: ProductionReport[] = inputArray.map((input) =>
      new ProductionReport().deserialize(input),
    );

    return workUsages;
  }

  clone(): ProductionReport {
    const clonedInstance: ProductionReport = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }

  get owners(): string {
    const { usageReports } = this;
    let ownersNames: Array<string> = [];

    usageReports.forEach((usageReport) => {
      const workOwnersNames: Array<string> = usageReport.work?.owners.split(' & ');
      ownersNames = ownersNames.concat(workOwnersNames);
    });

    return [...new Set(ownersNames)].join(' & ');
  }
}
