import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import {
  ROYALTIES_SOURCE_Y,
  ROYALTIES_SOURCE_QT,
  ROYALTIES_SOURCE_M,
  EARNINGS_BY_WORK,
  EARNINGS_BY_WORK_TOP,
} from 'app/shared/mocks';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  EarningsByWorkYearlyI,
  InfoResponseAPI,
  RoyaltiesPlatformI,
  RoyaltiesSummaryI,
  WorkFiltersI,
} from 'app/shared/interfaces';
import { Platform, Work, WorkRoyalties, WorkRoyaltiesDTO } from 'app/shared/models';

const TIMES = ['y', 'qt', 'm'];
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

interface RoyaltiesByPlatformAPIResponse {
  royalties: Array<RoyaltiesPlatformI>;
}

export interface EarningsByPlatformTrendElement {
  platform: Platform;
  total: number;
  months: Array<{
    month: string;
    earnings: number;
  }>;
}

interface EarningsByPlatformTrend {
  trend: Array<EarningsByPlatformTrendElement>;
}

interface EarningsByWorkResponse {
  earningsByWork: Array<Work>;
  yearEarnings: Array<number>;
  info: InfoResponseAPI;
}

@Injectable({
  providedIn: 'root',
})
export class EarningsService {
  private readonly apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  async getRoyaltiesByDigitalPlatform(timeFilter: string = null): Promise<EarningsByPlatformTrend> {
    let filter = '';
    if (timeFilter) {
      filter += `&period=${timeFilter}`;
    }
    const url = `${this.apiUrl}/platform/me/earnings/trend?${filter}`;
    const response: EarningsByPlatformTrend = await this.http
      .get<EarningsByPlatformTrend>(url, httpOptions)
      .toPromise();

    return response;
  }

  async getRoyaltiesBySource(timeFilter: string = null): Promise<RoyaltiesPlatformI[]> {
    let filter = '';
    let royaltiesBySource: Array<RoyaltiesPlatformI> = [];
    if (environment.useMocks) {
      if (timeFilter === TIMES[0]) {
        royaltiesBySource = ROYALTIES_SOURCE_Y;
      } else if (timeFilter === TIMES[1]) {
        royaltiesBySource = ROYALTIES_SOURCE_QT;
      } else if (timeFilter === TIMES[2]) {
        royaltiesBySource = ROYALTIES_SOURCE_M;
      }
    } else {
      if (timeFilter) {
        filter += `&period=${timeFilter}`;
      }
      const url = `${this.apiUrl}/earnings-source/${filter}`; // TODO: Endpoint
      const response: RoyaltiesByPlatformAPIResponse = await this.http
        .get<RoyaltiesByPlatformAPIResponse>(url, httpOptions)
        .toPromise();
      royaltiesBySource = response.royalties;
    }
    return royaltiesBySource;
  }

  async getWorksEarningsByYear(
    offset: number,
    limit: number,
    startYear: number,
    endYear: number,
    filters: WorkFiltersI,
  ): Promise<// EarningsByWorkResponseAPII |
  { workRoyalties: WorkRoyalties[]; info: InfoResponseAPI }> {
    // let earningsByWorkFetched: Work[];
    // let infoResponse: InfoResponseAPI;

    // if (environment.useMocks) {
    //   const allEarningsByWork = new Work()
    //     .deserializeArray(EARNINGS_BY_WORK.earningsByWork)
    //     .filter((earningWork: Work) => {
    //       if (!filters.text) {
    //         return true;
    //       }

    //       return (
    //         earningWork.title.includes(filters.text) || earningWork.unisonId.includes(filters.text)
    //       );
    //     });

    //   earningsByWorkFetched = allEarningsByWork.slice(offset, offset + limit);

    //   infoResponse = {
    //     count: allEarningsByWork.length,
    //     offset: offset.toString(),
    //     limit: limit.toString(),
    //   };
    // } else {
    let queryString = `?offset=${offset}&limit=${limit}`;

    if (startYear) {
      queryString += `&startYear=${startYear}`;
    }
    if (endYear) {
      queryString += `&endYear=${endYear}`;
    }
    if (filters && filters.text) {
      queryString += `&text=${filters.text}`;
    }

    const url = `${this.apiUrl}/composition/me/earnings/yearly/${queryString}`;

    const { works: workRoyaltiesApi, info } = await this.http
      .get<{ works: WorkRoyaltiesDTO[]; info: InfoResponseAPI }>(url)
      .toPromise();

    const workRoyalties = new WorkRoyalties().deserializeArray(workRoyaltiesApi);
    return {
      workRoyalties,
      info,
    };
  }

  getWorksEarningsByYearTop(numWorks: number, numYears: number): EarningsByWorkResponse {
    let earningsByWorkFetched: Work[];
    let infoResponse: InfoResponseAPI;

    if (environment.useMocks) {
      earningsByWorkFetched = new Work().deserializeArray(EARNINGS_BY_WORK_TOP.earningsByWork);

      infoResponse = {
        count: earningsByWorkFetched.length,
        offset: '0',
        limit: '0',
      };
    } else {
      // TODO: API Call
      // eslint-disable-next-line no-console
      console.log(numWorks, numYears);
    }

    return {
      earningsByWork: earningsByWorkFetched,
      yearEarnings: EARNINGS_BY_WORK.yearEarnings,
      info: infoResponse,
    };
  }
}
