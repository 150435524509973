/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { CollectionShare, CollectionShareDTO } from './collectionShare';
import { LiquidationDTO } from './liquidation';
import { Catalog, CatalogDTO } from './catalog';
import { UsageTypes } from './usage';

export interface CollectionShareLiquidationDTO {
  id?: string;
  amount?: number;
  dateFrom?: string;
  dateTo: string;
  type?: UsageTypes;
  shareId: string;
  catalogId?: string;
  liquidationId?: string;
  share?: CollectionShareDTO;

  createdAt?: string;
  updatedAt?: string;

  status?: string;
  // liquidation?: LiquidationDTO;
  // collectionShare?: CollectionShareDTO;
  // collectionShareId?: string;
  catalog?: CatalogDTO;
}

export class CollectionShareLiquidation implements Serializable<CollectionShareLiquidation> {
  id?: string;
  amount?: number;
  dateFrom?: string;
  dateTo?: string;
  type?: string;
  shareId?: string;
  catalogId?: string;
  liquidationId?: string;
  share?: CollectionShare;

  updatedAt?: string;
  createdAt?: string;

  status?: string;
  // liquidation?: Liquidation;
  // collectionShare?: CollectionShare;
  // collectionShareId?: string;
  catalog?: Catalog;

  deserialize(input: CollectionShareLiquidationDTO): CollectionShareLiquidation {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.amount = input.amount || 0;
    this.dateFrom = input.dateFrom || '';
    this.type = input.type || '';
    this.shareId = input.shareId || '';
    this.catalogId = input.catalogId || '';
    this.liquidationId = input.liquidationId || '';
    this.share = input.share ? new CollectionShare().deserialize(input.share) : null;

    this.updatedAt = input.updatedAt || '';
    this.createdAt = input.createdAt || '';

    this.status = input.status || '';
    // this.liquidation = new Liquidation().deserialize(input.liquidation);
    // this.collectionShare = new CollectionShare().deserialize(input.collectionShare);
    // this.collectionShareId = input.collectionShareId;
    this.catalog = input.catalog ? new Catalog().deserialize(input.catalog) : null;

    return this;
  }

  deserializeArray(
    inputArray: Array<CollectionShareLiquidationDTO>,
  ): Array<CollectionShareLiquidation> {
    const collectionShareLiquidations: Array<CollectionShareLiquidation> = inputArray.map((input) =>
      new CollectionShareLiquidation().deserialize(input),
    );

    return collectionShareLiquidations;
  }
}
