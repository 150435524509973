import type { Serializable } from 'app/core/interfaces';

import type { PlatformPlaysI } from '../interfaces';
import { Platform } from './platform';
import { Territory, TerritoryDTO } from './territory';

interface PlatformPlays {
  platform: Platform;
  plays: string;
}

export interface UsageTerritoryPlatformDTO {
  platformCount?: number;
  plays?: string;
  platforms?: PlatformPlaysI[];
  territory?: TerritoryDTO;
}

export class UsageTerritoryPlatform implements Serializable<UsageTerritoryPlatform> {
  platformCount?: number;
  plays?: string;
  platforms?: PlatformPlays[];
  territory?: Territory;

  deserialize(input: UsageTerritoryPlatformDTO): UsageTerritoryPlatform {
    if (!input) {
      return this;
    }

    this.platformCount = input.platformCount || 0;
    this.plays = input.plays || '';
    this.territory = input.territory ? new Territory().deserialize(input.territory) : undefined;

    if (input.platforms) {
      this.platforms = input.platforms.map(
        (platformPlay: PlatformPlaysI): PlatformPlays => {
          return {
            platform: new Platform().deserialize(platformPlay.platform),
            plays: platformPlay.plays,
          };
        },
      );
    } else {
      this.platforms = [];
    }

    return this;
  }

  deserializeArray(inputArray: Array<UsageTerritoryPlatformDTO>): Array<UsageTerritoryPlatform> {
    const usageTerritoryPlatforms: Array<UsageTerritoryPlatform> = inputArray.map((input) =>
      new UsageTerritoryPlatform().deserialize(input),
    );

    return usageTerritoryPlatforms;
  }

  clone(): UsageTerritoryPlatform {
    const clonedInstance: UsageTerritoryPlatform = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this,
    );

    return clonedInstance;
  }
}
