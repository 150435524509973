/* eslint-disable import/no-cycle */
import { Serializable } from 'app/core/interfaces';
import { RightHolder, RightHolderDTO } from './rightHolder';
import { Work, WorkDTO } from './work';
import { Catalog, CatalogDTO } from './catalog';
import { Territory, TerritoryDTO } from './territory';
import { CollectionShareLiquidation } from './collectionShareLiquidation';

export interface CollectionShareTerritoryDTO {
  collectionShareId: string;
  id: string;
  inclusionExclusionIndicator: 'I' | 'E';
  sequenceNumber: number;
  territory: TerritoryDTO;
  territoryId: number;
}
export interface CollectionShareDTO {
  id?: string;
  role?: string;
  compositionId?: string;
  rightHolderId?: string;
  parentId?: string;
  catalogId?: string;
  composition?: WorkDTO;
  rightHolder?: RightHolderDTO;
  collectionShareTerritories: Array<CollectionShareTerritoryDTO>;

  createdAt?: string;
  updatedAt?: string;

  submitterAgreementNumber?: string;
  societyAssignedAgreementNumber?: string;

  performanceSplit?: number;
  mechanicalSplit?: number;
  synchronizationSplit?: number;
  inclusionExclusionIndicator?: string;
  sequenceNumber?: number;
  rightHolderName?: string;
  work?: WorkDTO;
  territory?: Territory;
  territoryId?: number;
  catalog?: CatalogDTO;
  // collectionShareLiquidations?: CollectionShareLiquidation[]; // TODO API
  performanceSocietyId?: string;
  mechanicalSocietyId?: string;
  synchronizationSocietyId?: string;
}

export class CollectionShare implements Serializable<CollectionShare> {
  id?: string;
  role?: string;
  workId?: string;
  compositionId?: string;
  rightHolderId?: string;
  parentId?: string;
  catalogId?: string;
  work?: Work;
  rightHolder?: RightHolder;
  collectionShareTerritories: Array<CollectionShareTerritoryDTO>;

  createdAt?: string;
  updatedAt?: string;

  performanceSplit?: number;
  mechanicalSplit?: number;
  synchronizationSplit?: number;
  inclusionExclusionIndicator?: string;
  sequenceNumber?: number;
  rightHolderName?: string;
  territory?: Territory;
  territoryId?: number;
  catalog?: Catalog;
  collectionShareLiquidations?: CollectionShareLiquidation[];

  get territoriesString(): string {
    const { collectionShareTerritories } = this;

    const territoriesNames: Array<string> = collectionShareTerritories.map(
      (cShareTerritory) => cShareTerritory.territory.name,
    );

    return [...new Set(territoriesNames)].join(', ');
  }

  deserialize(input: CollectionShareDTO): CollectionShare {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.role = input.role || '';
    this.workId = input.compositionId || '';
    this.compositionId = input.compositionId || '';
    this.rightHolderId = input.rightHolderId || '';
    this.parentId = input.parentId || '';
    this.catalogId = input.catalogId || '';

    if (input.composition) {
      this.work = new Work().deserialize(input.composition);
    }

    if (input.work) {
      this.work = new Work().deserialize(input.work);
    }

    this.rightHolder = input.rightHolder ? new RightHolder().deserialize(input.rightHolder) : null;

    this.createdAt = input.createdAt || '';
    this.updatedAt = input.updatedAt || '';

    this.catalog = input.catalog ? new Catalog().deserialize(input.catalog) : null;
    this.performanceSplit = input.performanceSplit || 0;
    this.mechanicalSplit = input.mechanicalSplit || 0;
    this.synchronizationSplit = input.synchronizationSplit || 0;
    this.inclusionExclusionIndicator = input.inclusionExclusionIndicator || '';
    this.sequenceNumber = input.sequenceNumber || 0;
    this.rightHolderName = input.rightHolderName || '';
    this.territory = input.territory || null;
    this.territoryId = input.territoryId || 0;
    this.collectionShareTerritories = input.collectionShareTerritories || [];
    // this.collectionShareLiquidations = input.collectionShareLiquidations;

    return this;
  }

  deserializeArray(inputArray: Array<CollectionShareDTO>): Array<CollectionShare> {
    const collectionshares: Array<CollectionShare> = inputArray.map((input) =>
      new CollectionShare().deserialize(input),
    );

    return collectionshares;
  }
}
